import React from 'react';
import './AppStoreLinkModal.scss';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import close_icon from '../../Assets/Icons/close_icon.svg';
import { toggleAppStoreLinkModal, requestpickup } from '../../Redux/Actions';
import { ReactSVG } from 'react-svg'
import Helper from '../../Utils/Helper';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';

class AppStoreLinkModal extends React.Component {
    state = {
        isOpen: false,
        phoneNumber: '',
        country: 'US',
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pickup_loading && !this.props.pickup_loading) {
            if(this.props.pickup_success){
                Helper.showToast('success', 3000, this.props.pickup_msg);
                this.props.toggleAppStoreLinkModal(false);
            } else {
                Helper.showToast('error', 3000, this.props.pickup_msg);
            }
        }
    };

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
        }
    };

    closeModal = () => {
        this.props.toggleAppStoreLinkModal(false);
    };

    onHide = () => {
    };

    send = () => {
        const { phoneNumber } = this.state;
        const deploy ={
            phone: phoneNumber,
        }
        if(phoneNumber){
            this.props.requestpickup(deploy);
        };
    };
    
    render() {
        let { isOpen } = this.state;
        const { pickup_loading } = this.props;
        return (
            <Modal show={isOpen} onHide={this.onHide} className="app-link-modal-component">
                <div className="app-link-content v-r">
                    <div className="close-btn"> <ReactSVG src={ close_icon } className='icon-close' onClick={ () => this.closeModal() }/> </div>
                    <div className="title">Where should we send the link to download the app?</div>
                    <div className="phone-container">
                        <div className="v-c">
                            <ReactFlagsSelect className="custom-flag" defaultCountry={"US"} onSelect={val => this.setState({country: val})} searchable={true} showSelectedLabel={false} />
                            <div className="phone-content">
                                <input className="custom-input"
                                    type="number"
                                    placeholder="phone number"
                                    onChange={e => this.setState({phoneNumber: e.target.value})} 
                                />
                            </div>
                        </div>
                        <div className={`shadow-object box-item v-c h-c btn ${pickup_loading? 'disabled' : ''}`} onClick={()=>this.send()}>
                            {(pickup_loading) ? <div className="spinner" /> : "Send"}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isAppStoreLinkModalOpen,

        pickup_loading: state.Pickup.pickup_loading,
        pickup_success: state.Pickup.pickup_success,
        pickup_msg: state.Pickup.pickup_msg,
    }
}
  
export default connect(mapStateToProps, { 
    toggleAppStoreLinkModal,
    requestpickup
 })(withRouter(AppStoreLinkModal));
