import React from 'react';
import './LocationPage.scss';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { 
  Request,
  ServiceStart, 
  WhyUs,
  Convenience, 
  Quality, 
  Sustainability, 
  Service, 
  HowItWorks,
  Pricing,
  Partner,
  AboutUs,
  News,
  ProvideService,
  Help,
  Faqs,
  Submit,
  NearService,
  Cities,
} from '../../Components';
import { getLocationData, toggleHomeModal, getPhoneNumber, toggleRequestModal } from "../../Redux/Actions";
import { Helmet } from 'react-helmet';
import Helper from '../../Utils/Helper';
import _ from 'lodash'

class LocationPage extends React.Component {
  state ={
    current_city: '',
    howItWorks_index: 0
  }

  componentDidMount(){
    Helper.showSpinner();
    this.props.getLocationData('Fort Worth, TX');

    const doneRequest = sessionStorage.getItem("doneRequest");
    if(!doneRequest){
      this.props.toggleRequestModal(true);
    }

    const doneHomeModal = sessionStorage.getItem("doneHomeModal");
    if(!doneHomeModal){
      setTimeout(function() {
        this.props.toggleHomeModal(true);
      }.bind(this), 5*60*1000)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.city) {
      const params = new URLSearchParams(this.props.location.search);
      const city = this.props.match.params.city.replace(/-/g, ' ');
      if(this.state.current_city !== city){
        Helper.showSpinner();
        this.setState({current_city: city})
        this.props.getLocationData(city);
      }
    }

    if (prevProps.get_location_data_loading && !this.props.get_location_data_loading) {
      if(this.props.get_location_data_success){
        Helper.hideSpinner();
        this.props.getPhoneNumber(this.props.LocationData.city.phone);
      }
    }
  }

  selectIndex = (e) => {
    this.setState({howItWorks_index: e})
  }

  render() {
    const { LocationData } = this.props;
    const { howItWorks_index } = this.state;    
    const title =  `#1 ${LocationData && LocationData.city.name} Dry Cleaners + Laundry Services - Free PickUp & Delivery`
    const description = `New customers get 2 of 10 items minimum dry cleaned for FREE. We also free lifetime pick-up and delivery for all your dry cleaning and wash & fold needs in ${LocationData && LocationData.city.name} and nearby cities`
    return (
      <>
        {LocationData && <div className="landing-page">
          <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta property="type" content="website" />
            <meta property="url" content={window.location.href}/>
            <meta property="image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={title} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content="https://yellowbagcleaners.com/yellowbag.png"/>
          </Helmet>

          <Request/>
          <ServiceStart title={LocationData.title}/>
          <WhyUs city={LocationData.city.name}/>
          <Convenience selectIndex={(e)=> this.selectIndex(e)}/>
          <Quality />
          <Sustainability />
          <Service />
          <HowItWorks howItWorks_index={howItWorks_index}/>
          <Pricing city={LocationData.city.name} zipcode={LocationData.city.zipcode}/>
          <Partner partners={LocationData.partners[0]} city={LocationData.city.name}/>
          <AboutUs city={LocationData.city.name}/>
          <News />
          <ProvideService services={LocationData.services} city={LocationData.city.name}/>
          <Help address1={LocationData.locations[0].address1} address2={LocationData.locations[0].address2} city={LocationData.city.name} contact={LocationData.contact}/>
          <Faqs />
          <Submit city={LocationData.city.name}/>
          <NearService city={LocationData.city}/>
          <Cities service_areas={LocationData.service_areas} city={LocationData.city.name}/>
        </div>}
      </>
    )
  };
};

const mapStateToProps = (state) => ({  
  LocationData: state.Location.LocationData,
  get_location_data_loading: state.Location.get_location_data_loading,
  get_location_data_success: state.Location.get_location_data_success,
});

export default connect(mapStateToProps, {
  getLocationData,
  toggleHomeModal,
  getPhoneNumber,
  toggleRequestModal
})(withRouter(LocationPage));