import React from 'react';
import './HomeModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleHomeModal } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import close_icon_mobile from '../../Assets/Icons/close_icon_mobile.svg';
import { ReactSVG } from 'react-svg'
import { Cleaning } from '../';
import logoActive from '../../Assets/Icons/logo-active.png';

class HomeModal extends React.Component {
    state = {
        isOpen: true,
        info: ''
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            info: props.info
        }
    }

    closeModal = () => {
        sessionStorage.setItem("doneHomeModal", true);

        this.props.toggleHomeModal(false);
    }

    onHide = () => {
    }

    render() {
        let { isOpen, info } = this.state;
        return (
            <Modal show={isOpen} onHide={this.onHide} className="home-modal-component">
                <div className="home-content v-r">
                    <div className="close-btn" onClick={ () => this.closeModal() }> 
                        <ReactSVG src={ close_icon_mobile } className='icon-close'/>
                    </div>
                    <div className="logo-content">
                        <img src={logoActive} alt="logo" />
                    </div>
                    <Cleaning />
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isHomeModalOpen,
        info: state.Modal.info,
    }
}
  
export default connect(mapStateToProps, { 
    toggleHomeModal 
})(withRouter(HomeModal));
