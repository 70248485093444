import React from 'react';
import './Quality.scss';
import comma from '../../../Assets/Home/Quality/comma.png';
import quality_logo from '../../../Assets/Home/Quality/quality_logo.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


class Quality extends React.Component {

    render() {
        return (
            <div className="quality-component" id="section-quality">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 left-area">
                            <h1>Quality</h1>
                            <p>The YellowBag Promise</p>
                            <div className="details mt-3">
                                <div>
                                    <img src={comma} alt="comma-logo" className="comma-logo" />                            
                                </div>
                                <p className="ml-4">“Garments will come back looking like new or we redo it for no additional cost to you.”</p>
                            </div>
                        </div>
                        <div className="col-lg-6 v-c h-c">
                            <img src={quality_logo} alt="quality-logo" className="quality-logo" />                            
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Quality));
