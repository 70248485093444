/* @flow */

import { combineReducers } from 'redux';

import Modal from './Modal';
import Location from  './Location';
import Review from './Review';
import Post from './Post';
import Auth from './Auth';
import Plan from './Plan';
import Pricing from './Pricing';
import Contact from './Contact';
import Pickup from './Pickup';
import Phone from './Phone';
import Landing from './Landing';
import City from './City';
import Footer from './Footer';

export default combineReducers ({
    Modal,
    Location,
    Review,
    Post,
    Auth,
    Plan,
    Pricing,
    Contact,
    Pickup,
    Phone,
    Landing,
    City,
    Footer,
})