import React from 'react';
import './Cleaning.scss';
import arrow_right from '../../../Assets/Home/Start/arrow_right.png';
import car from '../../../Assets/Home/Cleaning/car.png';
import car_mobile from '../../../Assets/Home/Cleaning/car_mobile.png';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleHomeModal, zipcodeavailable } from '../../../Redux/Actions';
import Helper from '../../../Utils/Helper';
import _ from 'lodash'
class Cleaning extends React.Component {
    state = {
        zipcode: ''
    }

    
    componentDidUpdate(prevProps) {
        const {zipcode} = this.state;

        if (prevProps.zipcodeavailable_loading && !this.props.zipcodeavailable_loading) {
            Helper.hideSpinner();
            this.props.toggleHomeModal(false);

            if(this.props.zipcodeavailable_success){
                this.props.history.push({pathname: "/free-pickup", zipcode})
            } else {
                // Helper.showToast('error', 3000, this.props.zipcodeavailable_msg);
                this.props.history.push({pathname: "/free-pickup", zipcode: 'error'})
            }
        }
    }

    freePickUp(){
        const {zipcode} = this.state;
        if(zipcode){
            Helper.showSpinner();
            this.props.zipcodeavailable(this.state.zipcode);
        } else {
            Helper.showToast('error', 3000, "Please insert zip code!");
        }
    }

    render() {
        let text1 = 'FREE DRY CLEANING';
        let text2 = 'First 2 of 10 items minimum dry cleaned for free';
        let text3 = 'Get Free Dry Cleaning';
        
        if(this.props.location.pathname == '/college-services'){
            text1 = 'FREE WASH & FOLD';
            text2 = 'Your first bag is free when you sign up for any of our college plans';
            text3 = 'Request Our Services';
        }
        return (
            <div className="cleaning-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 order-lg-first order-second">
                            <h1>{text1}</h1>
                            <div className="description">
                                <p>{text2}</p>

                                <div className="left-main-content">
                                    <div>
                                        <div className="sub-title">Get FREE lifetime pick-up and delivery on all your dry cleaning and wash/folds</div>
                                        <p className="mt-4 mb-2">See if you qualify?</p>
                                        <div className="zip-code-container">
                                            <input className="custom-input"
                                                type="number"
                                                placeholder="What is your zipcode"
                                                onChange={e => this.setState({zipcode: e.target.value})} 
                                            />
                                            <div className="shadow-object box-item v-c h-c btn" onClick={()=>this.freePickUp()}>{text3}</div>
                                        </div>
                                    </div>
                                    <img src={arrow_right} alt="arrow" className="arrow" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 right-area show-web">
                            <img src={car} alt="car" className="car" />
                        </div>
                        <div className="col-lg-5 right-area mt-4 show-mobile order-lg-second order-first">
                            <img src={car_mobile} alt="car-mobile" className="car" />
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        zipcodeavailable_loading: state.Pickup.zipcodeavailable_loading,
        zipcodeavailable_success: state.Pickup.zipcodeavailable_success,
        zipcodeavailable_msg: state.Pickup.zipcodeavailable_msg,
    }
}

export default connect(mapStateToProps, {
    toggleHomeModal,
    zipcodeavailable
})(withRouter(Cleaning));
