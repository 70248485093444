import provide1 from '../../Assets/Service/ProvideService/provide1.png';
import provide2 from '../../Assets/Service/ProvideService/provide2.png';
import provide3 from '../../Assets/Service/ProvideService/provide3.png';
import provide4 from '../../Assets/Service/ProvideService/provide4.png';
import provide5 from '../../Assets/Service/ProvideService/provide5.png';
import provide6 from '../../Assets/Service/ProvideService/provide6.png';
import provide1_mobile from '../../Assets/Service/ProvideService/provide1_mobile.png';
import provide2_mobile from '../../Assets/Service/ProvideService/provide2_mobile.png';
import provide3_mobile from '../../Assets/Service/ProvideService/provide3_mobile.png';
import provide4_mobile from '../../Assets/Service/ProvideService/provide4_mobile.png';
import provide5_mobile from '../../Assets/Service/ProvideService/provide5_mobile.png';
import provide6_mobile from '../../Assets/Service/ProvideService/provide6_mobile.png';

export const ServiceList = [
    {image: provide1, logo_mobile: provide1_mobile, name: ' Wash & Fold', description: "Fill the bag to the top with your activewear, homewear and clothing that don't need pressing. We will clean and neatly fold your items. Your clothes are not mixed with other people clothes. We offer quarterly, six months and yearly subscription with our wash & fold service. You can contant u to learn more.  If you need the items hong, we will need you to provide hangers."},
    {image: provide2, logo_mobile: provide2_mobile, name: ' College Services', description: `We provide wash and folder and dry cleaning services to universities College is for growth and not for wasting precious time doing laundry. This is time that will yield massive returns if invested in your studies. <br /> We provide our service to Universities via our smart quick access lockers. Students and staff drop off your dirty garments at any of the smart lockers and pick them up perfectly clean within 48hrs. <br /> We share a percentage of our proceeds with the university so they can assist underprivileged students. We also offer semester and yearly plans. Please contact us to learn more.`},
    {image: provide3, logo_mobile: provide3_mobile, name: ' Commercial Services', description: "We offer two types of services for businesses: <br /> For small & medium size business, we officer free pick-up and delivery for all their dry cleaning, laundry, wash/fold and alteration needs. We service businesses in all verticals <br /> For medium and large businesses: we offer free pick and delivery and smart lockers services. We place our smart lockers at their facilities and their employees can drop off their dirty garments and pick up perfectly clean garments within 48hrs. <br /> Employers can provide employers with dry cleaning vouchers as an incentive for working from the office. "},
    {image: provide4, logo_mobile: provide4_mobile, name: ' Smart Lockers', description: "We offer our quick access smart lockers services to luxury apartments, medium - large size businesses, universities, Hotels, residential communities etc <br /> It allows anyone at those premises to to drop off their dirty garments at the locker using our mobile app and pick up a perfectly clean garment. You can see how our smart lockers worke here."},
    {image: provide5, logo_mobile: provide5_mobile, name: 'Airbnb', description: "Yellowbag allows local airbnb host to provide premium 5 star services to their guests. We take all the worries about perfectly clean linens so you can focus on growing your airbnb empire <br /> Trust us and you wouldn’t regret it. We understand the HUGE impact a bad review from a quest can have on your business. Say bye-bye to uncertainty with your linen and towels"},
    {image: provide6, logo_mobile: provide6_mobile, name: 'Pick-Up & Delivery Services', description: "No pickup and delivery fees! Yes! You heard that right. All you have to do is enter your zipcode to see if you qualify for our free lifetime pick up and delivery. We pick up your dirty garments and deliver perfectly clean garments. We offer this service for dry cleaning, wash/fold and alterations."},
]