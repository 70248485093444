import React from 'react';
import './WhyUs.scss';
import convenience from '../../../Assets/Home/Whyus/convenience.png';
import quality from '../../../Assets/Home/Whyus/quality.png';
import sustain from '../../../Assets/Home/Whyus/sustain.png';
import service from '../../../Assets/Home/Whyus/service.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Whyus =[
    {title: 'Convenience', img: convenience, section_id: '#section-convenience'},
    {title: 'Quality', img: quality, section_id: '#section-quality'},
    {title: 'Sustainability', img: sustain, section_id: '#section-sustainability'},
    {title: 'Service', img: service, section_id: '#section-service'},
]
class WhyUs extends React.Component {

    render() {
        const { city } = this.props;
        const pathName = this.props.location.pathname;        
        return (
            <div className="whyus-component">
                <div className="container">
                    <h1>{`Why use us ${city? `in ${city}` : ""}`}</h1>
                    <div className="row">
                        {Whyus.map((item, index)=> (
                            <a className="col-lg-3" key={index} href={`${pathName === "/" ? "": "/"}${pathName.split('/')[1]}/${item.section_id}`}>
                                <div className="why-item  v-c h-c">
                                    <img src={item.img} alt="logo" className="whyus-logo" />
                                    <p>{item.title}</p>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(WhyUs));
