export const FaqList1 = [
    {
        title: "Do I pay extral for pick up and delivery?",
        content: `No! Every yellowbag customer get free pick-up and delivery for life. Click <a href="/free-pickup" target="_blank" rel="noopener noreferrer">here</a> to see if you qualify`
    },
    {
        title: "How do I sign up for pick-up delivery?",
        content: `Use this <a href="/free-pickup" target="_blank" rel="noopener noreferrer">link</a> to sign up for our free pick and delivery service`
    },
    {
        title: "When should I expect my item to get a pickup and also delivered?",
        content: `After signing up for service, you’re assigned a route that will determine your pick and delivery date.`
    },
    {
        title: "Do you offer same-day pick and delivery services? ",
        content: `We don’t currently offer same day pick up and delivery service`
    },
    {
        title: "Can I get my items pick-up on none pick-up days?",
        content: `Not possible for now but we plan to add this option in the future`
    },
];
export const FaqList2 = [
    {
        title: "How do I pay for services?",
        content: `You pay by adding your payment information on your customer portal using our website or our mobile app. You can reach out to us if you need us to guide you through the process`
    },
    {
        title: "What payment options do you accept?",
        content: `We accept all major credit and debit cards`
    },
    {
        title: "How do I update my payment info?",
        content: `You can update your payment information in your client portal via our mobile app or website`
    },
];
export const FaqList3 = [
    {
        title: "How smart locker work?",
        content: `Go to how it works on the website home page to learn how our smart lockers work`
    },
    {
        title: "How to request a smart locker for your organization?",
        content: `Please click <a href="/#section-contact-us" target="_blank" rel="noopener noreferrer">here</a> to request a smart locker for your organization`
    },
    {
        title: "Having an issue using the locker?",
        content: `Contact us at <a href="mailto:service@yellowbagcleaners.com" target="_blank" rel="noopener noreferrer">service@yellowbagcleaners.com</a> if you need help operating our lockers`
    },
    {
        title: "Do I pay extra for using the lacker?",
        content: `No! Members of your organization get discounted pricing using our smart lockers since we pass the logistics savings to members of your organization.`
    },
];
export const FaqList4 = [
    {
        title: "Dry cleaning",
        content: `Click <a href="/free-pickup" target="_blank" rel="noopener noreferrer">here</a> to request our dry cleaning services`
    },
    {
        title: "Wash & fold",
        content: `Click <a href="/free-pickup" target="_blank" rel="noopener noreferrer">here</a> to request our wash and fold services`
    },
    {
        title: "Leather care",
        content: `Click <a href="/free-pickup" target="_blank" rel="noopener noreferrer">here</a> to request our leather care services`
    },
    {
        title: "Shoe care",
        content: `Click <a href="/free-pickup" target="_blank" rel="noopener noreferrer">here</a> to request our shoe care services`
    },
    {
        title: "Alteration",
        content: `Click <a href="/free-pickup" target="_blank" rel="noopener noreferrer">here</a> to request our alteration services`
    },
    {
        title: "Airbnb",
        content: `Click <a href="/#section-contact-us" target="_blank" rel="noopener noreferrer">here</a> to request our aibnb services`
    },
    {
        title: "Commercial services",
        content: `Click <a href="/#section-contact-us" target="_blank" rel="noopener noreferrer">here</a> to request our commercial services`
    },
    {
        title: "University service",
        content: `Click <a href="/#section-contact-us" target="_blank" rel="noopener noreferrer">here</a> to request University services`
    },
    {
        title: "Office services",
        content: `Click <a href="/#section-contact-us" target="_blank" rel="noopener noreferrer">here</a> to request our office services`
    },
];