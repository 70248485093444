import React from 'react';
import './Help.scss';
import mail_box from '../../../Assets/Home/Help/mail_box.png';
import call from '../../../Assets/Home/Help/call.png';
import message from '../../../Assets/Home/Help/message.png';
import phone from '../../../Assets/Home/Help/phone.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Help extends React.Component {

    render() {
        const { address1, address2, city, contact } = this.props
        return (
            <div className="help-component" id="section-contact-us">
                <div className="container">
                    <h1 className="text-center">We are here to help</h1>
                    <div className="row help-content">
                        <div className="col-lg-6 mt-4">                       
                            <div className="help-item">
                                <img src={mail_box} alt="item-icon" className="item-icon" />                            
                                <div>
                                    <div className="title">Drop us an email</div>
                                    <p>{contact.email}</p>
                                </div>
                            </div>
                        </div> 
                        <div className="col-lg-6 mt-4">                       
                            <div className="help-item">
                                <img src={call} alt="item-icon" className="item-icon" />                            
                                <div>
                                    <div className="title">call us</div>
                                    <p>{contact.phone}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">                                
                            <div className="help-item">
                                <img src={message} alt="item-icon" className="item-icon" />                            
                                <div>
                                    <div className="title">{`${city? `Address For ${city}` : "Chat with us with Address"} `}</div>
                                    <p>{address1}</p>
                                    <p>{address2}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4">                       
                            <div className="help-item">
                                <img src={phone} alt="item-icon" className="item-icon" />                            
                                <div>
                                    <div className="title">phone support</div>
                                    <p>{contact.phone_support}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Help));
