import React from 'react';
import './Feedback.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import play_icon from '../../Assets/Icons/play_icon.png';
import StarRatings from 'react-star-ratings';
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';
import { toggleVideoModal } from '../../Redux/Actions';


class Feedback extends React.Component {

    showVideo = () => {
        this.props.toggleVideoModal(true, this.props.info);
    };

    render() {
        const { className, info, city } = this.props;
        
        return (
            <div className={`feedback-component v-r round-object ${className || ''}`}>
                <div className="feedback-header" onClick={()=>this.showVideo()}>
                    <img src={info.image} alt="avatar" className="avatar" />
                    <img src={play_icon} alt="play-icon" className="play-icon" />                    
                </div>
                <div className="reviewer-info v-r v-c">
                    <h2>{info.name}</h2>
                    {!city && <div className="address v-c ">
                        {info.location}
                    </div>}
                    <div className="star-area">
                        <StarRatings
                            rating={info.rating}
                            starDimension="15px"
                            starSpacing="2.14px"
                            starRatedColor="#FFE006"
                            starEmptyColor="#D3EDFC"
                            svgIconViewBox={StarSVGViewBox}
                            svgIconPath={StarSVGIconPath}
                            />
                    </div>
                    <p className="feedback">{info.content}</p>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, { toggleVideoModal })(withRouter(Feedback));
