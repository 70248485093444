import React from 'react';
import './WelcomeStart.scss';
import Roland_photo from '../../../Assets/Welcome/Roland_photo.png';
import marker from '../../../Assets/Welcome/marker.png';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class WelcomeStart extends React.Component {

    render() {
        return (
            <div className="welcome-start-component">
                <div className="container">
                    <h1 className="text-center">Thank you for your interest in our services!</h1>
                    <div className="sub-title text-center">We have texted you a link to download our mobile application to complete your pick-up request</div>
                    <div className="marker-content">
                        <img src={marker} alt="marker" className="marker" />  
                    </div>
                    <div className="welcome-start-content">
                        <div className="description text-center">All new customers get their first 2 of 10 items minimum cleaned for free</div>
                        {/*<div className="v-c mt-3">
                            <img src={Roland_photo} alt="avatar" className="avatar" />                            
                            <p className="ml-3">Roland Omene, Co Founder at YellowBag</p>
                        </div>*/}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(WelcomeStart));
