import { Api } from './Api';

export function* signIn(email, password){
    const jsonData = yield Api.post("/login", {email, password});
    return jsonData;
}

export function* signOut() {
    const jsonData = yield Api.post("/logout", {});
    return jsonData;
}

export function* signUp(data){
    const jsonData = yield Api.post("/register", data);
    return jsonData;
}

export function* forgotPassword(data) {
    const jsonData = yield Api.post("/forgotpassword", data);
    return jsonData;
}

export function* resetPassword(data) {
    const jsonData = yield Api.post("/resetpassword", data);
    return jsonData;
}