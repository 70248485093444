import React from 'react';
import './Header.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg'
import phone from '../../Assets/Header/phone-call.png';
import phone_mobile from '../../Assets/Header/phone_mobile.png';

import logoActive from '../../Assets/Icons/logo-active.png';
import logoInactive from '../../Assets/Icons/logo-inactive.png';
import { getPhoneNumber } from '../../Redux/Actions';

class Header extends React.Component {
    state = {
        transform: false,
    }

    static getDerivedStateFromProps(props, state) {
        return {
            phoneNumber: props.phoneNumber
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll = (event) => {
        this.setState({
            transform: window.scrollY > 0 ? true : false
        });
    }

    showInactiveHeader = () => {
        const pathName = this.props.location.pathname;
        return (pathName === '/' || pathName === '/on-demand-dry-cleaning&laundry-services' || pathName === '/top-rank-dry-cleaning-and-laundry-services' || pathName === '/locations' || pathName === '/service-locations' || pathName === '/college-services') && !this.state.transform
    }

    hideHeader = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/sign-in' || pathName === '/forgot-password' ||
            pathName === '/welcome'
        ) 
    }

    hideRightMenu = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/on-demand-dry-cleaning&laundry-services' || pathName === '/top-rank-dry-cleaning-and-laundry-services' || pathName === '/free-pickup' || pathName === '/service-locations' || pathName === '/college-services'
        ) 
    }

    isPickupScreen = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/free-pickup'
        ) 
    }

    isLocationPage = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/locations' || pathName === '/locations/'
        ) 
    }

    login = () => {
        window.open("https://account.mydrycleaner.com/YellowBagTX");
    }

    render() {
        const { phoneNumber } = this.props;
        const request_title = this.props.location.pathname == '/college-services' ? 'Our Services' : 'Free Pick-Up';
        return (
            <div className={`header-component v-c ${this.showInactiveHeader() ? 'inactive' : ''}`} style={{display: this.hideHeader() && 'none'}}>
                <div className="container">
                    <div className="header-container v-c">
                        <div className="v-c">
                            <NavLink className="menu-item logo-active logo" exact to="/"><img src={logoActive} alt="logo-active" /></NavLink>
                            <NavLink className="menu-item logo-inactive logo" exact to="/"><img src={logoInactive} alt="logo-inactive" /></NavLink>
                            <img src={phone} alt="phone" className="ml-4 show-web"/>
                            <a href={`tel:+1${phoneNumber}`}>
                                <img src={phone_mobile} alt="phone_mobile" className="ml-4 show-mobile"/>
                            </a>
                            <div className="phone-txt ml-2 show-web">{phoneNumber}</div>
                            {this.isPickupScreen() && 
                                <div className="phone-txt ml-2 show-mobile">{phoneNumber}</div>
                            }
                        </div>
                        <div className="v-c">
                            <div className="v-c" style={{display: this.hideRightMenu() && 'none'}}>
                                <NavLink className="menu-item text-menu show-web" exact to="/">Home</NavLink>
                                <a className="menu-item text-menu show-web" href={`${this.isLocationPage()? "/locations/#section-pricing" : "/#section-pricing"}`}>Pricing</a>
                                <a className="menu-item text-menu show-web" href={`${this.isLocationPage()? "/locations/#section-services" : "#section-services"}`}>Services</a>
                                <div className="menu-item text-menu show-web login-btn" onClick={()=>this.login()}>Login</div>
                            </div>
                            <div style={{display: this.isPickupScreen() && 'none'}}>
                                <NavLink className="box-menu shadow-object" exact to="/free-pickup">Request {request_title}</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="v-c show-mobile mt-3 mobile-menu" style={{display: this.hideRightMenu() && 'none'}}>
                        <NavLink className="menu-item text-menu " exact to="/">Home</NavLink>
                        <a className="menu-item text-menu " href={`${this.isLocationPage()? "/locations/#section-pricing" : "/#section-pricing"}`}>Pricing</a>
                        <a className="menu-item text-menu " href={`${this.isLocationPage()? "/locations/#section-services" : "/#section-services"}`}>Services</a>
                        <div className="menu-item text-menu login-btn" onClick={()=>this.login()}>Login</div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        phoneNumber: state.Phone.phoneNumber,
    }
}

export default connect(mapStateToProps, { getPhoneNumber })(withRouter(Header));
