import React from 'react';
import './Pricing.scss';
import checked_icon from '../../../Assets/Home/Pricing/checked_icon.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPricing } from '../../../Redux/Actions';
import _ from 'lodash'
import { apply } from 'redux-saga/effects';
import Help from '../Help';
import Helper from '../../../Utils/Helper';

function innerFunc(item) {
    return {__html: item}
}

class Pricing extends React.Component {
    state = {
        location: '',
        Pricing: [],
        select_index: 0,
        zipcode: '76005',
        isShowMore: false,
    }

    componentDidMount(){
        if(this.props.zipcode){
            this.props.getPricing(this.props.zipcode);
            this.setState({zipcode: this.props.zipcode});
        } else {
            this.props.getPricing(this.state.zipcode);        
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.get_pricing_loading && !this.props.get_pricing_loading) {
            if(this.props.get_pricing_success){
                this.setState({Pricing: this.props.pricing_list})
                if(this.state.location){
                    this.setState({zipcode: this.state.location})           
                }
            } else {
                Helper.showToast('error', 3000, "Not available zipcode")
            }
        }
    }

    apply = () => {
        const { zipcode, location } = this.state;
        if(location){
            this.props.getPricing(location);   
        }
    }

    toggleCheck = (item, index) => {
        let category = [ ...this.state.category ];
        category[index] = {...category[index], checked: !item.checked};
        this.setState({ category });
    }

    seeMore(){
        this.setState({isShowMore: !this.state.isShowMore})
    }

    render() {
        const { city } = this.props;
        const { zipcode, select_index, subCategory, isShowMore} = this.state;
        let pricing_list = this.state.Pricing;
        let text1 = 'Pricing';
        if(this.props.location.pathname == '/college-services'){
            pricing_list = this.state.Pricing.filter(x => x.category.name == 'Wash & Fold ( College Plans)');
            text1 = 'College Plans';
        }
        return (
            <div className="pricing-component" id="section-pricing">
                <div className="container">
                    <h1 className="text-center">{text1}</h1>
                    <div className="row pricing-content">
                        <div className="col-lg-4">
                            <div className="apply-container">
                                <input className="number-input"
                                    placeholder="Enter zipcode here"
                                    type="number"
                                    onChange={e => this.setState({location: e.target.value})} 
                                />
                                <div className={`btn apply-btn v-c h-c ${this.props.get_pricing_loading ? 'disabled' : ''}`} onClick={()=>this.apply()}>
                                    {this.props.get_pricing_loading ? <div className="spinner" /> : 'Submit'}
                                </div>
                            </div>
                            {!_.isEmpty(pricing_list) && pricing_list.map((data, index)=> (
                                <div className="catetory-item v-c" key={index}>
                                    <div className="v-c">
                                        <img src={data.category.image} alt="logo" className="main-category-logo" />
                                        <p className="category-title ml-3">{data.category.name}</p>
                                    </div>
                                    <div className="btn check-container v-c h-c" onClick={()=> this.setState({select_index : index})}>
                                        {select_index === index ? <img src={checked_icon} alt="checked" className="checked" /> : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-lg-8 right-area">
                            <div className="title">{`Pricing for pick-up & delivery at ${zipcode}( Min order $39)${city ? `, ${city}` : ""}`}</div>
                            <div className="description">24/7 stores & smart lockers pricing are different. Check with your local store or locker host for their respective pricing.</div>
                            <div className="row">
                                {!_.isEmpty(pricing_list) && pricing_list[select_index].products.map((data, index)=> (
                                    (!isShowMore && index < 10 || isShowMore) && <div className="col-lg-6" key={index}>
                                        <div className="v-c subcategory-item">
                                            <div className="v-c">
                                                <img src={data.image} alt="logo" className="category-logo" />
                                                <div className="ml-3">
                                                    <p >{data.name}</p>
                                                    <div className="item-project-description" dangerouslySetInnerHTML={innerFunc(data.description)} />
                                                </div>
                                            </div>
                                            <p className="price">{data.price}</p>
                                        </div>
                                    </div>
                                    // : isShowMore ? <div className="col-lg-6" key={index}>
                                    //     <div className="v-c subcategory-item">
                                    //         <div className="v-c">
                                    //             <img src={data.image} alt="logo" className="category-logo" />
                                    //             <p className="ml-3">{data.name}</p>
                                    //         </div>
                                    //         <p className="price">{data.price}</p>
                                    //     </div>
                                    // </div> : null
                                ))}
                            </div>
                            <div className="show-more btn mt-5 v-c h-c" onClick={()=>this.seeMore()}>
                                <p>{isShowMore? "Show less" : "Show more"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        pricing_list: state.Pricing.pricing_list,
        get_pricing_success: state.Pricing.get_pricing_success,
        get_pricing_loading: state.Pricing.get_pricing_loading,
    }
}

export default connect(mapStateToProps, {getPricing})(withRouter(Pricing));
