import React from 'react';
import './Faqs.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Accordion } from '../../';
import { DropBox } from '../../';
import { FaqList1, FaqList2, FaqList3, FaqList4 } from "./FaqList";


const Category = [
    {id: '1', name: 'Pick-Up & Delivery', detail: FaqList1}, 
    {id: '2', name: 'Payments', detail: FaqList2}, 
    {id: '3', name: 'Smart Lackers', detail: FaqList3},
    {id: '4', name: 'Services', detail: FaqList4},
];


function innerFunc(item) {
    return {__html: item}
}

class Faqs extends React.Component {
    
    state = {
        category_id: '1',
        faqList: FaqList1
    }

    changeCategory = (e) => {
        this.setState({
            category_id: e.id, 
            faqList: e.detail
        })
    }

    render() {

        const { category_id } = this.state;

        return (
            <div className="faqs-component" id="section-FAQs">
                <div className="container">
                    <h1 className="text-center">FAQs</h1>
                    <div className="row category-row v-c show-web-flex">
                        {Category.map((item, index) => <div className={`category-item v-c h-c ${category_id === item.id ? 'active' : ''}`} key={index} onClick={e=>this.changeCategory(item)}>
                            {item.name}
                        </div>)}
                    </div>
                    <div className="show-mobile">
                        <DropBox 
                            value={"Pick-Up & Delivery"}
                            category={Category}
                            onChange={ e => this.changeCategory(e)}
                        />
                    </div>
                    <div className="faq-content">
                        {this.state.faqList.map((item, index) => <Accordion className="accordion second" title={item.title} key={index}>
                            <p dangerouslySetInnerHTML={innerFunc(item.content)} />
                        </Accordion>)}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Faqs));
