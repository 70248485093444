import React from 'react';
import './HowItWorks.scss';
import work1 from '../../../Assets/Home/HowItWorks/work1.png';
import work2 from '../../../Assets/Home/HowItWorks/work2.png';
import work3 from '../../../Assets/Home/HowItWorks/work3.png';
import work4 from '../../../Assets/Home/HowItWorks/work4.png';
import work5 from '../../../Assets/Home/HowItWorks/work5.png';
import work6 from '../../../Assets/Home/HowItWorks/work6.png';
import work7 from '../../../Assets/Home/HowItWorks/work7.png';
import work8 from '../../../Assets/Home/HowItWorks/work8.png';
import work9 from '../../../Assets/Home/HowItWorks/work9.png';
import { DropBox } from '../../';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const KioskStore = [
    { id: 0, img: work1, title: '24/7 Kiosk store', description: 'Drop-off your garments using our Kiosk that dispenses a free bag' },
    { id: 1, img: work2, title: 'Plant', description: 'We clean your garments at our state of the art eco-friendly plant. We only use chemicals friendly to your garments and our environment.' },
    { id: 2, img: work3, title: '27/7 Pick-Up', description: 'Pick-up your perfectly clean garments any time  using our 24/7 kiosk' },
];

const Delivery = [
    { id: 0, img: work4, title: 'Order', description: 'Place your order via our mobile app, website, SMS or by calling'},
    { id: 1, img: work5, title: 'FREE Pick-Up', description: 'We pick up your dirty garments from you for free. We also leave behind a pick-up bag for future orders'},
    { id: 2, img: work6, title: 'FREE Delivery', description: 'We deliver your perfectly clean garments within 48hr for an additional fee'},
];

const SmartLockers = [
    { id: 0, img: work7, title: '24/7 Drop Off', description: 'Drop off your dirty garments at yellowbag locker near you using our mobile app'},
    { id: 1, img: work8, title: 'Plant', description: 'We pick up your dirty garments from the locker and clean it at our state of the act plant. We only use chemicals eco friendly to your garments and  our environment' },
    { id: 2, img: work9, title: 'Pick-Up', description: 'We send you an SMS to notify you when your garment is ready for pick up in one off our lockers'},
];

let Category = [
    {id: 0, name: '24/7 Kiosk Powered Store', detail: KioskStore}, 
    {id: 1, name: 'Free pick-up and delivery', detail: Delivery}, 
    {id: 2, name: 'Smart Locker', detail: SmartLockers}
];


class HowItWorks extends React.Component {

    state = {
        category_id: 1,
        WorkBlocks: KioskStore
    }

    componentDidUpdate(prevProps){
        if(prevProps.howItWorks_index !== this.props.howItWorks_index){
            this.setState({category_id: this.props.howItWorks_index})
        }
    }

    changeCategory = (e) => {
        this.setState({
            category_id: e.id, 
            WorkBlocks: e.detail
        })
    }

    render() {
        const { category_id, WorkBlocks } = this.state;
        if(this.props.location.pathname == '/college-services'){
            Category = [
                {id: 1, name: 'Free pick-up and delivery', detail: Delivery}, 
            ];
        }
        return (
            <div className="howitworks-component" id="section-HowItWors">                
                <div className="container">
                    <h1>How it works</h1>
                    <div className="row category-row h-c show-web-flex">
                        {Category.map((item, index) => <div className={`col-lg-4 category-item v-c h-c ${category_id === item.id ? 'active' : ''}`} key={index} onClick={e=>this.setState({category_id: item.id, WorkBlocks: item.detail})}>
                            {item.name}
                        </div>)}
                    </div>
                    <div className="show-mobile">
                        <DropBox 
                            value={"24/7 Kiosk Powered Store"}
                            category={Category}
                            onChange={ e => this.changeCategory(e)}
                        />
                    </div>
                    <div className="row">
                        {WorkBlocks.map((item, index) => <div className="col-lg-4 sub-col v-c h-c" key={index}>
                            <div className="work-block">                                
                                <div className="work-content v-r v-c">
                                    <div className="logo-container v-c h-c">
                                        <img src={item.img} alt="work-img" />
                                    </div>
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}
export default connect(mapStateToProps, {})(withRouter(HowItWorks));
