
import { 
   CONTACT, CONTACT_FAIL, CONTACT_SUCCESS, 
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  contact as contactApi,
} from '../../Api';

function* contact(payload) {
  try {
      const result = yield contactApi(payload.data);
      if (result) {
          yield put({ type: CONTACT_SUCCESS, data: {msg: result.message}})
      } else {
          let message = result.message;
          let errors = result.errors || {};
          for (let item of Object.keys(errors)) {
              let error_part = errors[item];
              for (let msg of error_part) {
                  message += ' ' + msg;
              }
          }
          yield put({ type: CONTACT_FAIL, data: {msg: message}})
      }
  } catch (err) {
      yield put({ type: CONTACT_FAIL, data: {msg: "NET ERROR"}});
  }
}

export function* watchContact(){
  yield takeLatest(CONTACT, contact)
}