import React from 'react';
import './Partner.scss';
import partner_bg from '../../../Assets/Service/Partner/partner_bg.png';
import partner_bg_mobile from '../../../Assets/Service/Partner/partner_bg_mobile.png';
import location_icon from '../../../Assets/Service/Partner/location_icon.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash'

class Partner extends React.Component {

    render() {
        const { partners, city } = this.props;
        return (
            <>
                {!_.isEmpty(partners) && <div className="partner-component">
                    <div className="container">
                        <h1 className="text-center">{`YellowBag Partner in ${city}`}</h1>
                        <div className="row">
                            <div className="col-lg-6 left-area">
                                <img src={partner_bg} alt="partner-photo" className="partner-bg show-web" />
                                <img src={partner_bg_mobile} alt="partner-photo" className="partner-bg show-mobile" />
                                <div className="avatar-container v-c h-c">
                                    <div className="avatar-content v-c h-c">
                                        <img src={partners.image} alt="partner-photo" className="partner-photo" />
                                    </div>
                                </div>
                                <div className="name mt-3">{partners.name}</div>
                            </div>
                            <div className="col-lg-6 right-area">
                                <div className="v-c">
                                    <img src={location_icon} alt="location-icon" className="location-icon" />
                                    <p className="location-txt ml-3">{partners.location}</p>
                                </div>
                                <p className="mt-4">{partners.description}</p>
                            </div>
                        </div>
                    </div>
                </div>}
            </>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Partner));
