import React from 'react';
import './HomeStart.scss';
import arrow from '../../../Assets/Home/Start/arrow.png';
import homeStart_img from '../../../Assets/Home/Start/homeStart_img.png';
import homeStart_img_mobile from '../../../Assets/Home/Start/homeStart_img_mobile.png';
import delivery_img from '../../../Assets/Home/Start/delivery_img.png';
import delivery_img_mobile from '../../../Assets/Home/Start/delivery_img_mobile.png';
import play_icon from '../../../Assets/Icons/play_icon2.png';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { zipcodeavailable } from '../../../Redux/Actions';
import Helper from '../../../Utils/Helper';
import { toggleVideoModal } from '../../../Redux/Actions';
import _ from 'lodash'

class HomeStart extends React.Component {
    state = {
        zipcode: ''
    }

    componentDidUpdate(prevProps) {
        const {zipcode} = this.state;

        if (prevProps.zipcodeavailable_loading && !this.props.zipcodeavailable_loading) {
            Helper.hideSpinner();

            if(this.props.zipcodeavailable_success){
                this.props.history.push({pathname: "/free-pickup", zipcode})
            } else {
                Helper.showToast('error', 3000, this.props.zipcodeavailable_msg);
                this.props.history.push({pathname: "/free-pickup", zipcode: 'error'})
            }
        }
    }

    freePickUp(){
        const {zipcode} = this.state;
        if(zipcode){
            Helper.showSpinner();
            this.props.zipcodeavailable(this.state.zipcode);
        } else {
            Helper.showToast('error', 3000, "Please insert zip code!");
        }
    }

    playVideo(){
        this.props.toggleVideoModal(true);
    }

    render() {
        let text1 = '#1 On-Demand Dry Cleaning + Wash & Fold Services In DFW';
        let text2 = 'DRY CLEANING';
        let text3 = 'Your first 2 of 10 items dry cleaned for free';
        let text4 = 'Get Free Dry Cleaning';
        let start_img = homeStart_img;
        let start_img_mobile = homeStart_img_mobile;
        if(this.props.location.pathname == '/college-services'){
            text1 = '#1 Laundry Wash & Fold + Dry Cleaning Services For College Students';
            text2 = 'WASH & FOLD SERVICE';
            text3 = 'Your first bag is free when you sign up for any of our college plans';
            text4 = 'Request Our Services';
            start_img = delivery_img;
            start_img_mobile = delivery_img_mobile;
        }
        return (
            <div className="homestart-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>{text1}</h1>
                            <div className="description">
                                <div className="free-box v-c h-c">FREE</div>
                                <div className="sub-title">{text2}</div>
                                <p>{text3}</p>

                                <img src={arrow} alt="arrow" className="arrow" />

                                <div className="zip-code-container">
                                    <input className="custom-input"
                                        type="number"
                                        placeholder="What is your zipcode"
                                        onChange={e => this.setState({zipcode: e.target.value})} 
                                    />
                                    <div className="shadow-object box-item v-c h-c btn" onClick={()=>this.freePickUp()}>{text4}</div>
                                </div>
                                <div className="delivery-text">Free lifetime pick-up and delivery</div>
                            </div>
                        </div>
                        <div className="col-lg-6 right-area show-web">
                            <img src={start_img} alt="home-img" className="home-img" />
                            <img src={play_icon} alt="play-icon" className="play-icon" onClick={()=>this.playVideo()} /> 
                        </div>
                        <div className="col-lg-6 right-area show-mobile mt-4">
                            <img src={start_img_mobile} alt="home-img" className="home-img" />
                            <img src={play_icon} alt="play-icon" className="play-icon" onClick={()=>this.playVideo()} /> 
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        zipcodeavailable_loading: state.Pickup.zipcodeavailable_loading,
        zipcodeavailable_success: state.Pickup.zipcodeavailable_success,
        zipcodeavailable_msg: state.Pickup.zipcodeavailable_msg,
    }
}

export default connect(mapStateToProps, {
    zipcodeavailable,
    toggleVideoModal
})(withRouter(HomeStart));
