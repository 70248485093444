import React from 'react';
import './AboutUs.scss';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../../Constant';

import { Feedback } from '../../';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllReviews } from '../../../Redux/Actions';
import _ from 'lodash'

const OwlCarousel = Loadable(() => import('react-owl-carousel'));


class AboutUs extends React.Component {

    componentDidMount(){
        this.props.getAllReviews();
    }    

    render() {        
        const { review_list, city } = this.props;
        return (
            <>
                {!_.isEmpty(review_list) && <div className="aboutus-component">
                    <div className="container">
                        <h1 className="text-center">{`What customers are saying about us ${city ? `in ${city}` : ""}`}</h1>
                        <div className="">
                            <Media queries={{
                                lg: "(min-width: 992px)",
                                md: "(min-width: 768px)",
                                }}>
                                {matches => (
                                    <OwlCarousel
                                        className="owl-theme"
                                        rewind
                                        nav
                                        stagePadding={5}
                                        margin={15}
                                        items={ matches.lg ? 3 : matches.md ? 2 : 1}
                                        dots={false}
                                        loop={false}
                                        navText={[CarouselLeftButton, CarouselRightButton]}
                                        >
                                        { review_list.map((item, index) => <Feedback info={item} index={index} key={index} city={city}/>)}
                                    </OwlCarousel>
                                )}
                            </Media>
                        </div>
                    </div>
                </div>}
            </>
        )
    };
};

function mapStateToProps(state) {
    return {
        review_list: state.Review.review_list,        
    }
}

export default connect(mapStateToProps, {getAllReviews})(withRouter(AboutUs));
