import { SERVICE_MODAL_TOGGLE, HOME_MODAL_TOGGLE, REQUEST_MODAL_TOGGLE, TUTOR_MODAL_TOGGLE, VIDEO_MODAL_TOGGLE, APP_STORE_LINK_MODAL_TOGGLE } from '../Type';

const INITIAL = {
  isServiceModalOpen: false,
  isAppStoreLinkModalOpen: false,
  isHomeModalOpen: false,
  isTutorModalOpen: false,
  isRequestModalOpen: false,
  isVideoModalOpen: false
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case SERVICE_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isServiceModalOpen: isOpen, info };
    }

    case HOME_MODAL_TOGGLE: {
      const { isOpen } = action.data;
      return { ...state, isHomeModalOpen: isOpen,  };
    }

    case REQUEST_MODAL_TOGGLE: {
      const { isOpen } = action.data;
      return { ...state, isRequestModalOpen: isOpen };
    }

    case VIDEO_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isVideoModalOpen: isOpen, info };
    }

    case TUTOR_MODAL_TOGGLE: {
      const { isOpen, info, rect } = action.data;
      return { ...state, isTutorModalOpen: isOpen, info, rect };
    }

    case APP_STORE_LINK_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isAppStoreLinkModalOpen: isOpen };
    }

    default:
      return state;
  }
}