import React from 'react';
import './Submit.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { contact } from '../../../Redux/Actions';
import Helper from '../../../Utils/Helper';
import _ from 'lodash'

class Submit extends React.Component {
    state ={
        name: '',
        email: '',
        reason: '',
        phone: '',
        message: ''
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contact_loading && !this.props.contact_loading) {
            if(this.props.contact_success){                
                Helper.showToast('success', 3000, this.props.contact_msg);
            } else {
                Helper.showToast('error', 3000, this.props.contact_msg);
            }
        }
    }

    submit = () => {
        const { name, email, phone, reason, message } = this.state;

        if( name && email && phone && reason && message ){
            const deploy ={
                name,
                email,
                phone,
                reason,
                message,
                region: this.props.city? this.props.city : "homepage",
            }
            this.props.contact(deploy)
        } else {
            Helper.showToast('error', 3000, "Please fill all forms");

        }
    }

    render() {
        return (
            <div className="submit-component">
                <div className="container">
                    <div className="row submit-content">
                        <h1 className="text-center">We want to hear from you</h1>
                        <div className="row">
                            <div className="col-lg-6">
                                <input className="custom-input"
                                    placeholder="Name"
                                    onChange={e => this.setState({name: e.target.value})} 
                                />
                            </div>
                            <div className="col-lg-6">
                                <input className="custom-input"
                                    placeholder="Email"
                                    onChange={e => this.setState({email: e.target.value})} 
                                />
                            </div>
                            <div className="col-lg-6">
                                <input className="custom-input"
                                    placeholder="Reason"
                                    onChange={e => this.setState({reason: e.target.value})} 
                                />
                            </div>
                            <div className="col-lg-6">
                                <input className="custom-input"
                                    type="number"
                                    placeholder="Phone"
                                    onChange={e => this.setState({phone: e.target.value})} 
                                />
                            </div>
                            <div className="col-lg-12">
                                <textarea className="custom-input message"
                                    placeholder="Your message"
                                    multiline="true" 
                                    onChange={e => this.setState({message: e.target.value})} 
                                />
                            </div>
                        </div>
                        <div className={`apply-btn btn v-c h-c ${this.props.contact_loading ? 'disabled' : ''}`} onClick ={() => this.submit() }>
                            {this.props.contact_loading ? <div className="spinner" /> : 'Submit'}
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        contact_loading: state.Contact.contact_loading,
        contact_success: state.Contact.contact_success,
        contact_msg: state.Contact.contact_msg,
    }
}

export default connect(mapStateToProps, {contact})(withRouter(Submit));
