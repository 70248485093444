import { ZIP_CODE_AVAILBLE, REQUEST_PICKUP, REQUEST_NEW_ZIP } from '../Type';

export const zipcodeavailable = (zipcode) => {
  return {
    type: ZIP_CODE_AVAILBLE,
    zipcode: zipcode
  }
}

export const requestpickup = (data) => {
  return {
    type: REQUEST_PICKUP,
    data: data
  }
}

export const requestNewZip = (data) => {
  return {
    type: REQUEST_NEW_ZIP,
    data: data
  }
}