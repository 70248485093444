import React from 'react';
import './Cities.scss';
import location from '../../../Assets/Icons/location.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Cities extends React.Component {
    state ={
        isShowMore: false,
        showMoreTxt: 'Show more',
        showIndex: 6,
    }

    showMore(){
        const {isShowMore, showIndex} = this.state;
        this.setState({isShowMore: !isShowMore})
        if(isShowMore){
            this.setState({
                showIndex: 6,
                showMoreTxt: 'Show more'
            })
        } else {
            this.setState({
                showIndex: this.props.service_areas.length,
                showMoreTxt: 'Show less'
            })
        }
    };

    selectCity = (item) => {
        const cityLink = item.link.replace(/\s+/g, '-');
        this.props.history.push(cityLink)
    };

    render() {
        const {showMoreTxt, showIndex} = this.state;
        const { service_areas, city } = this.props;

        return (
            <div className="cities-component" id="section-cities">
                <div className="container">
                    <h1 className="text-center">{`Cities we service near ${city}`}</h1>
                    <div className="row show-web-flex">
                        {service_areas.map((item, index)=> (
                            <div className="col-lg-4 location-item v-c" key={index} onClick={()=>this.selectCity(item)}>
                                <img src={location} alt="location" className="location" />
                                <p>{item.name}</p>
                            </div>                 
                        ))}
                    </div>
                    <div className="row show-mobile">
                        {service_areas.map((item, index)=> (
                            index < showIndex && (
                                <div className="col-lg-4 location-item v-c" key={index} onClick={()=>this.selectCity(item)}>
                                    <img src={location} alt="location" className="location" />
                                    <p>{item.name}</p>
                                </div>                 
                            )
                        ))}
                    </div>
                    <div className="show-more btn v-c h-c show-mobile mt-4" onClick={()=>this.showMore()}>{showMoreTxt}</div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Cities));
