import { GET_LANDING1_DATA, GET_LANDING1_DATA_SUCCESS, GET_LANDING1_DATA_FAIL } from '../Type';

const INITIAL = {
  Landing1Data: null,
  get_landing1_data_loading: false,
  get_landing1_data_success: false
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_LANDING1_DATA: {
      return {
        get_landing1_data_loading: true,
        get_landing1_data_success: false,
      }
    }
    case GET_LANDING1_DATA_FAIL: {
        return {
          Landing1Data: null,
          get_landing1_data_loading: false,
          get_landing1_data_success: false
        }
    }
    case GET_LANDING1_DATA_SUCCESS: {
      return {
        Landing1Data: action.data,
        get_landing1_data_loading: false,
        get_landing1_data_success: true
      }
    }

    default:
        return state;
  }
}