export const Privacy = [
    { 
        title: '', 
        content: `
            We make our living providing you with our services, not by collecting and selling your data, nor by serving you ads.  As such, we will never (never, ever!) use your personal information for any purpose other than to process your orders and administer your YellowBag account.  Don’t you wish more companies would keep their “privacy” policies this simple?
        `
    },
];

export const Term = [
    { 
        title: '', 
        content: `
            These terms and conditions ("User Terms") apply to your visit to and your use of our website at YellowbagCleaners.com (the "Website"), the Service and the Application (as defined below), as well as to all information, recommendations and/or services provided to you on or through the Website, the Service and the Application.
            <br />
            <br />
            PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING YellowBag  APPLICATIONS OR ANY SERVICES PROVIDED BY US, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS AND CONDITIONS, DO NOT ACCESS OR USE YELLOWBAG LLC APPLICATIONS OR ANY SERVICES PROVIDED BY US.
        `
    },
    { 
        title: 'YellowBag', 
        content: `Your contracting partner is YellowBag, a corporation established in the state of Texas (‘YellowBag LLC").`
    },
    { 
        title: 'How is a contract concluded between YellowBag and you?', 
        content: `
            By using the Website, Application or the Service, you enter into a contract with YellowBag (the "Contract"). In order to be able to use the Website, Application or Service, you first need to sign up with YellowBag. When signing up, you are obligated to provide YellowBag with your personal information, mobile telephone number, address for pick-up and delivery, and credit card data. Upon successful completion of your signing up with YellowBag, YellowBag will provide you with a personal account, which may be accessible for you with a password of your choice. 
            <br>
            You have to be 18 years of age or older to use the Service, Website, or Application. If you reside in a jurisdiction that restricts the use of the Service, Website, or Application because of age, or restricts the ability to enter into contracts such as this one due to age, you must abide by such age limits and you must not use the Service and the Application. You represent that if you are an individual, you are of legal age to enter into a binding contract, or that if you are registering on behalf of a legal entity, that you are authorized to enter into, and bind the entity to, these User Terms and register for the Service and the Application.
        `
    },
    { 
        title: 'Your use of the Website, Application, or the Service', 
        content: `
            You warrant that the information you provide to YellowBag is accurate and complete. YellowBag is entitled at all times to verify the information that you have provided and to refuse the Service or use of the Application without providing reasons.
            <br />
            You may only access the Service using authorized means. It is your responsibility to check to ensure you download the correct Application for your device. YellowBag is not liable if you do not have a compatible mobile device or if you download the wrong version of the Application for your mobile device. YellowBag reserves the right to terminate the Service and the use of the Application should you be using the Service or Application with an incompatible or unauthorized device. 
            <br />
            By using the Website, Application or the Service, you further agree that: 
            <br />
            You will only use the Service, Website, or download the Application for your sole, personal use and will not resell it to a third party; 
            <br />
            You will not authorize others to use your account; 
            <br />
            You will not assign or otherwise transfer your account to any other person or legal entity;
            <br />
            You will not use an account that is subject to any rights of a person other than you without appropriate authorization; 
            <br />
            You will not use the Service, Website, or Application for unlawful purposes, including but not limited to sending or storing any unlawful material or for fraudulent purposes; 
            <br />
            You will not use the Service , Website, or Application to cause nuisance, annoyance or inconvenience; 
            <br />
            You will not impair the proper operation of the network; 
            <br />
            You will not try to harm the Service , Website, or Application in any way whatsoever; 
            <br />
            You will not copy, or distribute the Website, Application, or other YellowBag Content without written permission from YellowBag;
            <br />
            You will keep secure and confidential your account password or any identification we provide you which allows access to the Service, Website, and the Application; 
            <br />
            You will provide us with whatever proof of identity we may reasonably request; 
            <br />
            You are aware that when requesting cleaning services or receiving notifications by SMS (if available in your jurisdiction), standard messaging charges will apply;
            <br />
            You will not use the Service, Website, or Application with an incompatible or unauthorized device; 
            <br />
            You will comply with all applicable law from your home nation, the country, state and/or city in which you are present while using the Application, Website, or Service.
            <br />
            YellowBag reserves the right to immediately terminate the Service and the use of the Website or Application should you not comply with any of the above rules.
        `
    },
    { 
        title: 'Payment ', 
        content: `
            The Instructor may cancel lessons by giving 24 hours prior notice to the Student. In instances where a lesson was pre-paid, the Instructor must reschedule the appointment at a time agreeable to both parties. If no such time is agreed upon, the student can be issued a refund for the missed lesson, if prepaid. 
            <br />
            The use of the Website and Application is free of charge. YellowBag reserves the right to introduce a fee for the use of the Application. If YellowBag decides to introduce such a fee, YellowBag shall inform you accordingly and allow you to either continue or terminate the Contract. 
            <br />
            YellowBag shall charge you for the services you request, which may include dry cleaning, laundered shirts, wash & fold, or related services ("Cleaning"). You agree that you will pay for all Cleaning you purchase through YellowBag, and that YellowBag may charge your credit card account as provided by you when registering for the Service for the Cleaning (including any taxes, late fees, or additional fees as applicable) that may be accrued by or in connection with your account. You are responsible for the timely payment of all fees and for providing YellowBag with a valid credit card account for payment of all fees at all times. Any payment made is non-refundable. 
            <br />
            YellowBag may use a third-party payment processor (the "Payment Processor") to link your credit card account to the Application and Service. The processing of payments or credits, as applicable, in connection with your use of the Website, Application and Service will be subject to the terms, conditions and privacy policies of the Payment Processor and your credit card issuer in addition to these User Terms. YellowBag is not responsible for any errors by the Payment Processor. In connection with your use of the Services, YellowBag will obtain certain transaction details, which YellowBag will use solely in accordance with its Privacy Policy. 
        `
    },
    { 
        title: 'Disclaimer Regarding Dry Cleaning Services', 
        content: `
            YellowBag does not provide dry cleaning or Laundering services and is not a dry cleaner. YellowBag offers information and a method to connect dry cleaners and parties seeking dry cleaning services, but does not and does not intend to provide dry cleaning services or act in any way as a dry cleaners, and has no responsibility or liability for any dry cleaning services provided by DRY CLEANERS.
        `
    },
    { 
        title: 'Damaged or Lost Items', 
        content: `It is our priority to supply the best care to your garment and we appreciate your trust.`
    },
    { 
        title: 'Missing Items', 
        content: `While it is never our intention for an item to go missing, occasionally it can happen. If this is the case, please let us know immediately by emailing service@yellowbagcleaners.com. Notifying the delivery agent or notifying YellowBag via SMS is insufficient. In order to receive reimbursement, any missing item must be reported to our customer support team and a claim report must be completed within seven (7) days of the delivery of the order, in order to ensure the maximum likelihood that it will be located. All claims are reviewed on a case-by-case basis. Items are considered lost ten (10) business days after the initial claim has been made. All decisions on lost items are in YellowBag's sole discretion and are final.`
    },
    { 
        title: 'Reimbursement Guidelines', 
        content: `
            The Student agrees that any intellectual property or other learning aids provided by the Instructor in connection with the Services may only be used by the Instructor and Student for the Student’s educational purposes, and that Student shall obtain rights to the intellectual property and learning aids, which shall remain the express property of the Instructor.
            <br />    
            For Wash and Fold/Hang Dry, Dry Cleaning and laundered shirts, any reimbursement for item(s) or order deemed lost by YellowBag shall be limited to and shall not exceed ten (10) times the charge for cleaning the lost item(s) (not inclusive of tax and other fees) regardless of brand, price or condition of the garment, or $500, whichever is lower. To the fullest extent permitted by law, YellowBag's aggregate liability in connection with a lost item shall not exceed the foregoing amount. YellowBag's offer for reimbursement is valid for ten (10) business days once the offer is made. Any attempts at redemption beyond that will be evaluated on a case by case basis. All decisions on lost items are in YellowBag's sole discretion and are final.
            <br />
            Exceptions to this missing items policy include:
            <br />
            (i) Socks, which will be reimbursed at $5 per pair in YellowBag credit, regardless of brand, price or condition
            <br />
            (ii) YellowBag does not accept liability for any personal laundry bags that are not Press branded bags
            <br />
            (iii) YellowBag does not take responsibility for any loose items lost when submitted in a Press bag, such as; watches, jewelry, cufflinks, money, wallets, etc. and shall have no liability for such items.
        `
    },
    { 
        title: 'Damaged Items', 
        content: `
            Although we do our best to prevent any damages to your garment, unfortunately every garment, regardless of brand or quality, will wear out over time, and there is nothing YellowBag can do to predict when this will happen, or prevent it. For damage related to normal wear and tear, YellowBag does not provide reimbursement or compensation.
            <br />
            Per industry standards, the following are considered to be normal wear and tear:
            <br />
            (i) Shrinkage from repeated laundering
            <br />
            (ii) Color fading
            <br />
            (iii) Small holes or tears
            <br />
            (iv) Discoloration caused by hygiene or beauty products (deodorant, perfume, cologne)
            <br />
            (v) Button damage
            <br />
            (vi) Thinning of fabric
            <br />
            (vii) Pilling
        `
    },
    { 
        title: 'Hang Dry', 
        content: `
            Because hang dry items are not being placed in a dryer, we cannot guarantee that the items will return dry. We recommend removing the plastic from your hang dry and hanging them up to continue drying once you receive your order. Due to the turnaround and the thickness of certain items, the items may have a “musty smell.” Please keep this in mind when creating orders with longer turnaround that also include hang dry.
            <br />
            YellowBag additionally is not liable for damage caused by you not selecting the correct service when placing an order (e.g. selecting wash and fold instead of dry cleaning, forgetting to add hang dry service), or when items are sent in incorrect bags (e.g. dry cleaning placed inside a labeled wash and fold bag). YellowBag is also not liable for damage caused by, but not limited to loose items like pens and lipstick.
            <br />
            Any damaged items must be reported by emailing us at support@presscleaners.com within seven (7) days of delivery of the order. Notifying the delivery agent or notifying YellowBag via SMS is insufficient. All damaged items must inspected by YellowBag through digital photos of each damaged item within seven (7) days of delivery of the order. All decisions on damaged items are in YellowBag's sole discretion and are final.
        `
    },
    { 
        title: 'Reimbursement Guidelines', 
        content: `
            For any items, regardless of service, deemed damaged outside of the above normal wear and tear, YellowBag may reimburse you by paying up to ten (10) times the charge for cleaning the item(s) (not inclusive of tax and other fees) for which the damage occurred regardless of brand, price or condition of the garment(s), or $500, whichever is lower. To the fullest extent permitted by law, YellowBag aggregate liability in connection with a damaged item shall not exceed the foregoing amount. YellowBag's offer for reimbursement is valid for ten (10) business days once the offer is made. Any attempts at redemption beyond that will be evaluated on a case by case basis. All decisions on damaged items are in YellowBag's sole discretion and are final.
            <br />
            In order to receive reimbursement in cash, the damaged item must be given to YellowBag to be inspected and subsequently donated and proof of purchase must be emailed to support@presscleaners.com if the item value is stated to be above $20. If you wish to keep your item, only reimbursement in YellowBag credit will be offered.
        `
    },
    { 
        title: 'Pick up and Delivery', 
        content: `
            Our goal is to provide valuable and timely service to all of our customers and partners. Therefore, you can edit the time of a pickup or delivery up to two (2) hours before the requested time. If you cancel a pickup less than two (2) hours before your requested time, rescheduled a delivery after the designated time for change, or if our partners cannot deliver or pickup an order because you or your designee failed to appear, we may charge a cancellation fee in a reasonable amount (currently set at the city minimum fee).
            <br />
            We hope to provide a convenient service to all our customers and can leave orders outside residences or with a doorman, provided the customer gives written consent. However, once delivery has been made, we will not be held responsible or liable for the loss or damage of any items. It is the customer’s sole responsibility to ensure the safety of items after delivery by YellowBag.
        
        `
    },
    { 
        title: 'Inventory', 
        content: `
            YellowBag reserve the right to keep records of your garments and retain, store, display or reproduce such records which may include images or photographs, and associate such records with your name or other profile information for commercially reasonable periods for archival, inventory purposes, quality control, loss prevention, and or data collection.
        `
    },
    { 
        title: 'License Grant, Restrictions and Copyright Policy', 
        content: `
            For the purpose of this User Term, the following definitions apply: 
            <br />
            "Content" means all content featured or displayed, including, but not limited to, logos, icons, trademarks, text, graphics text, graphics, photographs, images, moving images, sound, illustrations, music, software (excluding the Application), opinions, remarks, comments, artwork, links, questions, suggestions, information or other materials. 
            <br />
            "YellowBag Content" means Content owned or used by YellowBag, its affiliates or licensors and made available through the Website, Service or Application, including any Content licensed from a third party, but excluding User Content. 
            <br />
            "User" means a person who accesses or uses the Service or Application. 
            <br />
            "User Content" means Content that a User posts, uploads, publishes, submits or transmits to be made available on the Website or through the Service or Application. 
            <br />
            "Collective Content" means, collectively, YellowBag Content and User Content. 
            <br />
            Subject to your compliance with these User Terms, YellowBag grants you a limited, non-exclusive, non-transferable license: 
            <br />
            (i) to view, download and print any YellowBag Content solely for your personal and non-commercial purposes; and 
            <br />
            (ii) to view any User Content to which you are permitted access solely for your personal and non-commercial purposes. 
            <br />
            You have no right to sublicense the license rights granted in this section. 
            <br />
            You may not use, copy, adapt, modify, create derivative works from, distribute, license, sell, transfer, publicly display, publicly perform, reproduce, transmit, stream, broadcast or otherwise exploit the Website, Service, Application or Collective Content, except as expressly permitted in these User Terms. You may not reuse any Collective Content without first obtaining the written consent of YellowBag. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by YellowBag or its licensors, except for the licenses and rights expressly granted in these User Terms. 
        `
    },
    { 
        title: 'License Granted by User', 
        content: `
            We may, in our sole discretion, permit Users to post, upload, publish, submit or transmit User Content on the Website or through the Service or Application. User Content will be deemed non-confidential and non-proprietary. Accordingly, YellowBag shall have the non-exclusive, royalty-free, right to use, copy, distribute and disclose to third parties any User Content for any purpose, in any medium and throughout the world ("License Grant"). 
            <br />
            You acknowledge that YellowBag only acts as a passive conduit for the distribution of the User Content and is not responsible or liable to you or to any third party for the content or accuracy of the User Content. YellowBag shall not be continuously monitoring User Content published by you or moderating between Users, nor shall YellowBag be under an obligation to do so. Without limiting the foregoing, you acknowledge and agree that any remarks, opinions, comments, suggestions and other information expressed or included in the User Content do not necessarily represent those of YellowBag. 
            <br />
            Any use by you of the User Content is entirely at your own risk. You represent and warrant that any User Content posted or transmitted by you is original to you and does not copy the work of any third party or otherwise infringe any third party intellectual property rights, rights of privacy or personality rights and does not contain any defamatory or disparaging statements. Furthermore, you represent and warrant that you have the capacity to grant the license as stipulated in this paragraph. 
            <br />
            You agree to indemnify and keep YellowBag, its affiliates and licensors indemnified against all costs, expenses, damages, losses and liabilities incurred or suffered by YellowBag or its affiliated companies related to any User Content posted or transmitted by you or your other use of the Website, the Service or the Application. 
            <br />
            YellowBag reserves the right at its sole discretion to block or remove (in whole or in part) any User Content posted or transmitted by you and which YellowBag believes is not in accordance with these User Terms (including materials which infringe or may infringe third party intellectual property rights, rights of privacy or personality rights), or is otherwise unacceptable to YellowBag. 
            <br />
            You agree to promptly notify YellowBag in writing of any User Content which breaches these User Terms. You agree to provide to YellowBag sufficient information to enable YellowBag to investigate whether such User Content breaches these User Terms. YellowBag agrees to make good faith efforts to investigate such complaint and shall take such action as YellowBag in its sole discretion decides. However, YellowBag does not warrant or represent that it will block or remove (in whole or in part) such user Content. 
        `
    },
    { 
        title: 'Application License', 
        content: `
            Subject to your compliance with these User Terms, YellowBag grants you a limited non-exclusive, non-transferable license to download and install a copy of the Application on a single mobile device that you own or control and to run such copy of the Application solely for your own personal use. 
            <br />
            You shall not (i) license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Service or Application in any way; (ii) modify or make derivative works based upon the Service or Application; (iii) create Internet "links" to the Service or "frame" or "mirror" any Application on any other server or wireless or Internet-based device; (iv) reverse engineer or access the Application in order to (a) design or build a competitive product or service, (b) design or build a product using similar ideas, features, functions or graphics of the Service or Application, or (c) copy any ideas, features, functions or graphics of the Service or Application, or (v) launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, web indexers, bots, viruses or worms, or any program which may make multiple server requests per second, or unduly burdens or hinders the operation and/or performance of the Service or Application. 
            <br />
            You shall not: (i) send spam or otherwise duplicative or unsolicited messages in violation of applicable laws; (ii) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or violative of third party privacy rights; (iii) send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs; (iv) interfere with or disrupt the integrity or performance of the Website, the Application or Service or the data contained therein; or (v) attempt to gain unauthorized access to the Website, the Application or Service or its related systems or networks. 
            <br />
            YellowBag will have the right to investigate and prosecute violations of any of the above to the fullest extent of the law. YellowBag may involve and cooperate with law enforcement authorities in prosecuting users who violate these User Terms. You acknowledge that YellowBag has no obligation to monitor your access to or use of the Website, Service, Application or Collective Content or to review or edit any Collective Content, but has the right to do so for the purpose of operating the Website, the Application and Service, to ensure your compliance with these User Terms, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. YellowBag reserves the right, at any time and without prior notice, to remove or disable access to any Collective Content that YellowBag, at its sole discretion, considers to be in violation of these User Terms or otherwise harmful to the Website, the Service or Application. 
        `
    },
    { 
        title: 'Intellectual Property Ownership', 
        content: `
            YellowBag alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to the Website, Application and the Service and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Website, Application or the Service. 
            <br />
            These User Terms do not constitute a sale and do not convey to you any rights of ownership in or related to the Website, the Application or the Service, or any intellectual property rights owned by YellowBag. YellowBag's name, logo, and the product names associated with the Application and Service are trademarks of YellowBag, its affiliated companies or third parties, and no right or license is granted to use them.
        `
    },
    { 
        title: 'App Store Sourced Application', 
        content: `
            With respect to any Application accessed through or downloaded from the Apple App Store ("App Store Sourced Application"), you will use the App Store Sourced Application only: (i) on an Apple-branded product that runs iOS (Apple’s proprietary operating system software); and (ii) as permitted by the “Usage Rules” set forth in the Apple App Store Terms of Service. YellowBag reserves all rights in and to the Application not expressly granted to you under these User Terms. 
            <br />
            You acknowledge and agree that (i) these User Terms are valid between you and YellowBag only, and not Apple, and (ii) YellowBag, not Apple, is solely responsible for the App Store Sourced Application and content thereof. Your use of the App Store Sourced Application must comply with the App Store Terms of Service. 
            <br />
            You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App Store Sourced Application. 
            <br />
            In the event of any failure of the App Store Sourced Application to conform to any applicable warranty, you may notify Apple, and Apple will, where applicable, refund the purchase price for the App Store Sourced Application to you and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App Store Sourced Application. As between YellowBag and Apple, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of YellowBag. 
            <br />
            You and YellowBag acknowledge that, as between YellowBag and Apple, Apple is not responsible for addressing any claims you have or any claims of any third party relating to the App Store Sourced Application or your possession and use of the App Store Sourced Application, including, but not limited to: (i) product liability claims; (ii) any claim that the App Store Sourced Application fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. 
            <br />
            You and YellowBag acknowledge that, in the event of any third party claim that the App Store Sourced Application or your possession and use of that App Store Sourced Application infringes that third party’s intellectual property rights, as between YellowBag and Apple, YellowBag, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by this Agreement. 
            <br />
            You and YellowBag acknowledge and agree that Apple, and Apple’s subsidiaries, are third party beneficiaries relating to your license of the App Store Sourced Application, and that, upon your acceptance of these User Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce the rights under these User Terms as related to your license of the App Store Sourced Application against you as a third party beneficiary thereof. 
            <br />
            Without limiting any other provisions of these User Terms, you must comply with all applicable third party terms of agreement when using the App Store Sourced Application.
        `
    },
    { 
        title: 'Text messaging', 
        content: `
            When you opt-in to the service, we will send you an SMS message to confirm your signup. 
            <br />
            We are able to deliver messages to the following mobile phone carriers: 
            <br />
            Major carriers: AT&T, Verizon Wireless, Sprint, T-Mobile, U.S. Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile. 
            <br />
            Minor carriers: Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless, Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5 Star Wireless). 
            <br />
            As always, message and data rates may apply for any messages sent to you from us and to us from you. If you have any questions about your text plan or data plan, it is best to contact your wireless provider. 

        `
    },
    { 
        title: 'Third Party Interactions', 
        content: `
            During the use of the Website, the Application and the Service, links to websites that are owned and controlled by third parties may be provided from time to time in order to enter into correspondence with, purchase goods or services from, participate in promotions of third parties. These links take you off the Website, the Application and the Service and are beyond YellowBag's control. 
            <br />
            During use of the Website, the Application and the Service, you may enter into correspondence with, purchase goods and/or services from, or participate in promotions of third party service providers, advertisers or sponsors showing their goods and/or services through a link on the Website or through the Application or Service. These links take you off the Website, the Application and the Service and are beyond YellowBag's control. The websites you can link to have their own separate terms and conditions as well as a privacy policy. YellowBag is not responsible and cannot be held liable for the content and activities of these websites. You therefore visit or access these websites entirely at your own risk. 
            <br />
            Please note that these other websites may send their own cookies to users, collect data or solicit personal information, and you are therefore advised to check the terms of use or privacy policies on those websites prior to using them. 
        `
    },
    { 
        title: 'Term and termination of the contract', 
        content: `
            The Contract between YellowBag and you is concluded for an indefinite period. 
            <br />
            You are entitled to terminate the Contract at all times by permanent deletion of the Application installed on your smart phone, thus disabling the use by you of the Application and the Service. You can close your user account at any time by contact YellowBag's customer support at team@Presscleaners.com. 
            <br />
            YellowBag is entitled to terminate the Contract at all times and with immediate effect (by disabling your use of the Application and the Service) if you: (a) violate or breach any term of these User Terms, or (b) in the opinion of YellowBag, misuse the Application or the Service. YellowBag is not obliged to give notice of the termination of the Contract in advance. After termination YellowBag will give notice thereof in accordance with these User Terms. 
        `
    },
    { 
        title: 'Invalidity of one or more provisions', 
        content: `
            The invalidity of any term of these User Terms shall not affect the validity of the other provisions of these User Terms. 
            <br />
            If and to the extent that any provision of these User Terms is invalid, or is unacceptable in the given circumstances according to the criteria of reasonableness and fairness, a provision shall apply between the parties instead that is acceptable considering all the circumstances and which corresponds with the provisions of the void part as much as possible, taking into account the content and the purpose of these User Terms. 

        `
    },
    { 
        title: 'Modification of the Service and User Terms', 
        content: `
            YellowBag reserves the right, at its sole discretion, to modify or replace any of these User Terms, or change, suspend, or discontinue the Service or Application (including without limitation, the availability of any feature, database, or content) at any time by posting a notice on the Website or by sending you notice through the Service, Application or via email. YellowBag may also impose limits on certain features and services or restrict your access to parts or all of the Service without notice or liability.
        `
    },
    { 
        title: 'Notice', 
        content: `
            YellowBag may give notice by means of a general notice on the Service or Application, or by electronic mail to your email address on record in YellowBag's account information, or by written communication sent by regular mail to your address on record in YellowBag's account information. 
        `
    },
    { 
        title: 'Assignment', 
        content: `
            You may not assign your rights under these User Terms without prior written approval of YellowBag. 
        `
    },
    { 
        title: 'Privacy Policy ', 
        content: `
            YellowBag may collect and processes the personal data of the visitors of the Website and users of the Application according to the Privacy Policy. 
        `
    },
    { 
        title: 'Applicable law and Venue', 
        content: `
            The Terms and your use of the Service, including, but not limited to, the Application will be governed by and construed in accordance with the laws of the State of Texas, applicable to agreements made and to be entirely performed within the State of Texas, without resort to its conflict of law provisions. In the event of any matter or dispute arising out of or in connection with these Terms, you and YellowBag will submit to the exclusive jurisdiction of the state and federal courts in the State of Texas. 
        `
    },
    { 
        title: 'Miscellaneous', 
        content: `
            If any part of these Terms is determined to be invalid or unenforceable pursuant to applicable law, including, without limitation, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms will continue in full effect. Only you and YellowBag are entitled to enforce these Terms. No third party will be entitled to enforce any of the terms and conditions herein.
        `
    },

    { 
        title: 'Final provision', 
        content: `
            The English text of these User Terms constitutes the sole authentic text. In the event of any discrepancy between the English text and a translation into a foreign language, the English text shall prevail. 
            <br />
            Our Corporate Address: 
            <br />
            YellowBag LLC
            <br />
            3980 N Collins St # 146. 
            <br />
            Fort Worth, Texas 76005
            <br />
            U.S.A. 
            <br />
            *You may also contact our member support team at service@yellowbagcleaners.com.
            <br />
            Last Modified: June 29, 2021
        `
    },
];