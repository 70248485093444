import React from 'react';
import './Service.scss';
import check_icon from '../../../Assets/Home/Service/check_icon.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

let ServiceExperience =[
    '24/7 drop off and pick up', 'Free life time pick and delivery',
    'Easy access smart lockers ', '48 hours turn around time',
    'Yellow bag promise', 'Exceeding expectations'
]

class Service extends React.Component {

    render() {
        if(this.props.location.pathname == '/college-services'){
            ServiceExperience =[
                'Free life time pick and delivery',
                '48 hours turn around time',
                'Yellow bag promise', 'Exceeding expectations'
            ]
        }
        return (
            <div className="service-component" id="section-service">
                <div className="container">
                    <h1 className="text-center">Service - wow experience</h1>
                    <div className="row experience-content">
                        {ServiceExperience.map((data, index)=> (
                            <div className="col-lg-6 mt-4" key={index}>
                                <div className="service-item">
                                    <img src={check_icon} alt="check-icon" className="check-icon" />                            
                                    <p>{data}</p>
                                </div>
                            </div>                 
                        ))}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Service));
