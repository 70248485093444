import arrowLeft from './Assets/Icon/arrow-left.png';
import arrowRight from './Assets/Icon/arrow-right.png';

export const StarSVGViewBox = "0 0 16 15";
export const StarSVGIconPath = "M7.90577 0.587492L9.82622 5.13287L14.7428 5.55524C15.0838 5.58469 15.2225 6.01017 14.9637 6.23414L11.2344 9.46512L12.3519 14.2717C12.4294 14.6057 12.0675 14.8684 11.7746 14.6909L7.54927 12.1427L3.32397 14.6909C3.03025 14.8676 2.6691 14.6049 2.7466 14.2717L3.86415 9.46512L0.134072 6.23337C-0.124778 6.00939 0.0131721 5.58392 0.354947 5.55447L5.27155 5.13209L7.192 0.587492C7.3253 0.271292 7.77247 0.271292 7.90577 0.587492Z";

export const CarouselLeftButton = `
    <div style='width:45px;height:45px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 17px rgba(42, 26, 127, 0.2);'>
        <img src='${arrowLeft}' alt='nav-arrow' />
    </div>
`;
export const CarouselRightButton = `
    <div style='width:45px;height:45px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(42, 26, 127, 0.2);'>
        <img src='${arrowRight}' alt='nav-arrow' />
    </div>
`;

export const API_URL = "https://api.yellowbagcleaners.com/api";
export const ONLINE_URL = "https://yellowbagcleaners.com/";

export const GradeList = [
    { id: 1, grade: '0 - 2', age_period: '5 - 7', age_min: 5, age_max: 7},
    { id: 2, grade: '3 - 5', age_period: '8 - 10', age_min: 8, age_max: 10},
    { id: 3, grade: '3 - 9', age_period: '8 - 14', age_min: 8, age_max: 14},
    { id: 4, grade: '5 - 9', age_period: '10 - 14', age_min: 10, age_max: 14},
    { id: 5, grade: '6 - 9', age_period: '11 - 14', age_min: 11, age_max: 14},
    { id: 6, grade: '8+', age_period: '13+', age_min: 13, age_max: null},
    { id: 7, grade: '9+', age_period: '14+', age_min: 14, age_max: null},
]

export const GetDashboardUrl = (user, token) => {
    
}

export const IOS_APP_LINK = "http://onelink.to/vgqyxk";
export const ANDROID_APP_LINK = "http://onelink.to/vgqyxk";
