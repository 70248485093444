import React from 'react';
import './ProvideService.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleServiceModal } from '../../../Redux/Actions';

function innerFunc(item) {
    return {__html: item}
}

class ProvideService extends React.Component {

    state ={
        isLearnMoreModal: false
    }

    learnMore = (item) => {
        this.props.toggleServiceModal(true, {title: item.name, more: item.description});
    }

    render() {
        const { services, city } = this.props;
        return (
            <div className="provideService-component" id="section-services">
                <div className="container">
                    <h1 className="text-center">{`Services We Offer ${city ? `near you in ${city}` : ''}`}</h1>
                    <div className="row">
                        {services.map((item, index)=> (
                            <div className="col-lg-4 mt-5" key={index}>
                                <div className="provide-item">
                                    <img src={item.image} alt="item-logo" className="item-logo" />                         
                                    <div className="title">{item.name}</div>
                                    <p dangerouslySetInnerHTML={innerFunc(item.description)} />
                                    <div className="learn-more btn" onClick={()=>this.learnMore(item)}>Learn more</div>
                                </div>
                            </div>                 
                        ))}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {
    toggleServiceModal
})(withRouter(ProvideService));
