import React from 'react';
import './NearService.scss';
import map from '../../../Assets/Service/NearService/map.png';
import map_mobile from '../../../Assets/Service/NearService/map_mobile.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class NearService extends React.Component {

    render() {
        const { city } = this.props;
        return (
            <div className="near-service-component">
                <div className="container">
                    <div className="near-content">
                        <div className="title">
                        <h1 className="text-center">{city.name}</h1>
                        </div>
                        <div className="description">
                            <p>{city.description}</p>
                        </div>
                    </div>
                    <div className="map-mobile show-mobile mt-4">
                                            
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(NearService));
