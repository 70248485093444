/**
 * Modal
 */
export const SERVICE_MODAL_TOGGLE            = 'SERVICE_MODAL_TOGGLE';
export const HOME_MODAL_TOGGLE            = 'HOME_MODAL_TOGGLE';
export const REQUEST_MODAL_TOGGLE            = 'REQUEST_MODAL_TOGGLE';

export const TUTOR_MODAL_TOGGLE               = 'TUTOR_MODAL_TOGGLE';
export const VIDEO_MODAL_TOGGLE               = 'VIDEO_MODAL_TOGGLE';
export const APP_STORE_LINK_MODAL_TOGGLE               = 'APP_STORE_LINK_MODAL_TOGGLE';



/**
 * Phone
 */
 export const GET_PHONE            = 'GET_PHONE';

/**
 * Auth
 */
export const AUTH_SIGN_IN                     = 'AUTH_SIGN_IN';
export const AUTH_SIGN_IN_SUCCESS             = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAIL                = 'AUTH_SIGN_IN_FAIL';

export const AUTH_SIGN_OUT                    = 'AUTH_SIGN_OUT';
export const AUTH_SIGN_OUT_SUCCESS            = 'AUTH_SIGN_OUT_SUCCESS';
export const AUTH_SIGN_OUT_FAIL               = 'AUTH_SIGN_OUT_FAIL';

export const AUTH_SIGN_UP                     = 'AUTH_SIGN_UP';
export const AUTH_SIGN_UP_SUCCESS             = 'AUTH_SIGN_UP_SUCCESS';
export const AUTH_SIGN_UP_FAIL                = 'AUTH_SIGN_UP_FAIL';

export const AUTH_FORGOT_PASSWORD             = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_SUCCESS     = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_FAIL        = 'AUTH_FORGOT_PASSWORD_FAIL';

export const AUTH_RESET_PASSWORD              = 'AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_SUCCESS      = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAIL         = 'AUTH_RESET_PASSWORD_FAIL';

export const AUTH_UPDATE_PLAN                 = 'AUTH_UPDATE_PLAN';

/**
 * Location
 */
export const GET_LOCATION_DATA                  = 'GET_LOCATION_DATA';
export const GET_LOCATION_DATA_SUCCESS          = 'GET_LOCATION_DATA_SUCCESS';
export const GET_LOCATION_DATA_FAIL             = 'GET_LOCATION_DATA_FAIL';

/**
 * Cities
 */
 export const GET_CITY_LIST                  = 'GET_CITY_LIST';
 export const GET_CITY_LIST_SUCCESS          = 'GET_CITY_LIST_SUCCESS';
 export const GET_CITY_LIST_FAIL             = 'GET_CITY_LIST_FAIL';

/**
 * Footer
 */
 export const GET_FOOTER_DATA                  = 'GET_FOOTER_DATA';
 export const GET_FOOTER_DATA_SUCCESS          = 'GET_FOOTER_DATA_SUCCESS';
 export const GET_FOOTER_DATA_FAIL             = 'GET_FOOTER_DATA_FAIL';

/**
 * Landing
 */
 export const GET_LANDING1_DATA                  = 'GET_LANDING1_DATA';
 export const GET_LANDING1_DATA_SUCCESS          = 'GET_LANDING1_DATA_SUCCESS';
 export const GET_LANDING1_DATA_FAIL             = 'GET_LANDING1_DATA_FAIL';

/**
 * Review
 */
export const GET_ALL_REVIEWS                  = 'GET_ALL_REVIEWS';
export const GET_ALL_REVIEWS_SUCCESS          = 'GET_ALL_REVIEWS_SUCCESS';
export const GET_ALL_REVIEWS_FAIL             = 'GET_ALL_REVIEWS_FAIL';

/**
 * Post
 */
export const GET_ALL_POSTS                    = 'GET_ALL_POSTS';
export const GET_ALL_POSTS_SUCCESS            = 'GET_ALL_POSTS_SUCCESS';
export const GET_ALL_POSTS_FAIL               = 'GET_ALL_POSTS_FAIL';

/**
 * Plan
 */
export const UPDATE_PLAN                      = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS              = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAIL                 = 'UPDATE_PLAN_FAIL';

export const GET_PLAN                         = 'GET_PLAN';
export const GET_PLAN_SUCCESS                 = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAIL                    = 'GET_PLAN_FAIL';

/**
 * Pricing
 */
export const GET_PRICING                    = 'GET_PRICING';
export const GET_PRICING_SUCCESS            = 'GET_PRICING_SUCCESS';
export const GET_PRICING_FAIL               = 'GET_PRICING_FAIL';

/**
 * Contact
 */
export const CONTACT                    = 'CONTACT';
export const CONTACT_SUCCESS            = 'CONTACT_SUCCESS';
export const CONTACT_FAIL               = 'CONTACT_FAIL';

/**
 * Pick up
 */
export const ZIP_CODE_AVAILBLE                    = 'ZIP_CODE_AVAILBLE';
export const ZIP_CODE_AVAILBLE_SUCCESS            = 'ZIP_CODE_AVAILBLE_SUCCESS';
export const ZIP_CODE_AVAILBLE_FAIL               = 'ZIP_CODE_AVAILBLE_FAIL';

export const REQUEST_PICKUP                    = 'REQUEST_PICKUP';
export const REQUEST_PICKUP_SUCCESS            = 'REQUEST_PICKUP_SUCCESS';
export const REQUEST_PICKUP_FAIL               = 'REQUEST_PICKUP_FAIL';

export const REQUEST_NEW_ZIP                    = 'REQUEST_NEW_ZIP';
export const REQUEST_NEW_ZIP_SUCCESS            = 'REQUEST_NEW_ZIP_SUCCESS';
export const REQUEST_NEW_ZIP_FAIL               = 'REQUEST_NEW_ZIP_FAIL';


