import {
    GET_CITY_LIST, GET_CITY_LIST_SUCCESS, GET_CITY_LIST_FAIL,
} from '../Type';
import { takeLatest, put } from 'redux-saga/effects';
import {
    getCityList as getCityListApi
} from '../../Api';

function* getCityList(payload){
    try {
        const result = yield getCityListApi(payload.page)
        if (result) {
            yield put({ type: GET_CITY_LIST_SUCCESS, data: result })
        } else {
            yield put({ type: GET_CITY_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_CITY_LIST_FAIL, data: [] })
    }
}

export function* watchGetCityList(){
    yield takeLatest(GET_CITY_LIST, getCityList)
}