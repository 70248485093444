import { GET_PHONE} from '../Type';

const INITIAL = {
  phoneNumber: process.env.REACT_APP_PHONE,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_PHONE: {
      return { ...state, phoneNumber: action.phone,  };
    }
    default:
      return state;
  }
}