import { GET_FOOTER_DATA, GET_FOOTER_DATA_SUCCESS, GET_FOOTER_DATA_FAIL } from '../Type';

const INITIAL = {
  FooterData: null,
  get_footer_data_loading: false,
  get_fotter_data_success: false
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_FOOTER_DATA: {
      return {
        get_footer_data_loading: true,
        get_fotter_data_success: false,
      }
    }
    case GET_FOOTER_DATA_FAIL: {
        return {
          FooterData: null,
          get_footer_data_loading: false,
          get_fotter_data_success: false
        }
    }
    case GET_FOOTER_DATA_SUCCESS: {
      return {
        FooterData: action.data,
        get_footer_data_loading: false,
        get_fotter_data_success: true
      }
    }

    default:
        return state;
  }
}