import React from 'react';
import './Convenience.scss';
import convenice_logo1 from '../../../Assets/Home/Convenience/convenice_logo1.png';
import convenice_logo2 from '../../../Assets/Home/Convenience/convenice_logo2.png';
import convenice_logo3 from '../../../Assets/Home/Convenience/convenice_logo3.png';
import app_store from '../../../Assets/Icons/app_store.png';
import play_store from '../../../Assets/Icons/play_store.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleAppStoreLinkModal } from '../../../Redux/Actions';
import { IOS_APP_LINK, ANDROID_APP_LINK } from "../../../Constant";

let ConvenienceList =[
    {num: '01', logo: convenice_logo1, title: '24/7 Kiosk powered storefront', description: 'Drop-off and pick up your garments at any time', detail: 'Drop-off and pick up your garment at any time at any of our 24/7 stores powered by KIOSK'},
    {num: '02', logo: convenice_logo2, title: 'FREE Pick Up & Delivery', description: 'We pick up and deliver your clean garments at no additional cost for your entire life when you sign up for our services.', detail: 'We pick up and deliver your clean garments at no additional cost for your entire life when you sign up for our services.'},
    {num: '03', logo: convenice_logo3, title: 'Quick Access Smart Lockers ', description: 'We placed high-tech lockers at offices, luxury apartments, universities, and residential communities.', detail: 'We placed high-tech lockers at offices, luxury apartments, universities, and residential communities. Contact us to request a local for your facility. We’re different. We pay royalties to our locker partners.'},
]

class Convenience extends React.Component {

    state ={
        select_index: 0,
        mobile_select: false
    }

    selctIndex(index){
        this.setState({
            select_index: index,
            mobile_select: true
        });
        this.props.selectIndex(index);
    }

    gotoApple = () => {
        window.open(IOS_APP_LINK);
    };

    gotoPlayStore = () => {
        window.open(ANDROID_APP_LINK);
    };
    
    render() {
        const {select_index, mobile_select} = this.state;
        if(this.props.location.pathname == '/college-services'){
            ConvenienceList =[
                {num: '01', logo: convenice_logo2, title: 'FREE Pick Up & Delivery', description: 'We pick up and deliver your clean garments at no additional cost for your entire life when you sign up for our services.', detail: 'We pick up and deliver your clean garments at no additional cost for your entire life when you sign up for our services.'},
            ]
        }
        return (
            <div className="convenience-component" id="section-convenience">
                <div className="container">
                    <h1 className="text-center">Convenience</h1>
                    <div className="row">
                        <div className="col-lg-7">
                            {ConvenienceList.map((data, index)=> (
                                <div key={index} >
                                    <div className={`convenience-item v-c ${select_index === index? "active" : ""}`} onClick={()=>this.selctIndex(index)}>
                                        <div className="number">{data.num}</div>
                                        <div className="detail-area">
                                            <div className="title">{data.title}</div>
                                            <p>{data.description}</p>
                                        </div>
                                    </div>
                                    {(select_index === index && mobile_select) && <div className="down-apps show-mobile">
                                        <img src={data.logo} alt="convenice-logo" className="convenice-logo" />
                                        <div className="mt-3">
                                            <p className="text-center">{data.detail} <strong ><a className="strong btn" href={`${window.location.pathname}#section-HowItWors`}>Let’s see how it works</a></strong></p>
                                            <p className="text-center">Download our app to get started</p>
                                        </div>
                                        <div className="apple-google-store">                                    
                                            <img src={app_store} alt="app-store" className="app-link" onClick={()=>this.gotoApple()}/>
                                            <img src={play_store} alt="play-store" className="app-link ml-3" onClick={()=>this.gotoPlayStore()}/>
                                        </div>
                                    </div>}
                                </div>
                            ))}
                        </div>
                        <div className="col-lg-5 v-c h-c show-web">
                            <div className="down-apps">
                                <img src={ConvenienceList[select_index].logo} alt="convenice-logo" className="convenice-logo" />
                                <div className="mt-3">
                                    <p className="text-center">{ConvenienceList[select_index].detail} <strong ><a className="strong btn" href={`${window.location.pathname}#section-HowItWors`}>Let’s see how it works</a></strong></p>
                                    <p className="text-center">Download our app to get started</p>
                                </div>
                                <div className="apple-google-store">                                    
                                    <img src={app_store} alt="app-store" className="app-link" onClick={()=>this.gotoApple()}/>
                                    <img src={play_store} alt="play-store" className="app-link ml-3" onClick={()=>this.gotoPlayStore()}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {toggleAppStoreLinkModal})(withRouter(Convenience));
