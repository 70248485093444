import React from 'react';
import './HomePage.scss';
import { ServiceList } from "./ServiceList";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { toggleRequestModal, toggleHomeModal, getPhoneNumber } from '../../Redux/Actions';
import { 
  Request,
  HomeStart, 
  WhyUs, 
  Convenience, 
  Quality, 
  Sustainability, 
  Service, 
  HowItWorks,
  Pricing,
  AboutUs,
  News,
  ProvideService,
  Help,
  Faqs,
  Submit
} from '../../Components';

const contact = {
  address: "",
  email: "service@yellowbagcleaners.com",
  phone: process.env.REACT_APP_PHONE,
  phone_support: "Available between 10am - 5pm"
};

class HomePage extends React.Component {

  state ={
    howItWorks_index: 0
  }

  constructor(props) {
    super(props);
    this.workContainer = React.createRef();
  }

  componentDidMount(){
    const doneRequest = sessionStorage.getItem("doneRequest");
    if(!doneRequest){
      this.props.toggleRequestModal(true);
    }

    const doneHomeModal = sessionStorage.getItem("doneHomeModal");
    if(!doneHomeModal){
      setTimeout(function() {
        this.props.toggleHomeModal(true);
      }.bind(this), 5*60*1000)
    }

    this.props.getPhoneNumber(process.env.REACT_APP_PHONE);
  }

  selectIndex = (e) => {
    this.setState({howItWorks_index: e})
  }

  render() {
    const { howItWorks_index } = this.state;
    const title = "Ranked #1 For Dry Cleaning + Wash & Fold Service Near You - YellowBag";
    const description = "New customers get 2 of 10 items minimum dry cleaned for FREE. Get free lifetime pick-up and delivery for all your dry cleaning and wash & fold needs. See if you qualify."
    return (
      <div className="home-page" ref={this.workContainer}>
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://yellowbagcleaners.com/yellowbag.png" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={title} />
          <meta itemprop="description" content={description} />
          <meta itemprop="image" content="https://yellowbagcleaners.com/yellowbag.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content="https://yellowbagcleaners.com/yellowbag.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title}/>
          <meta name="twitter:description" content={description}/>
          <meta name="twitter:image" content="https://yellowbagcleaners.com/yellowbag.png"/>
        </Helmet>

        <Request />
        <HomeStart />
        <WhyUs />
        <Convenience selectIndex={(e)=> this.selectIndex(e)}/>
        <Quality />
        <Sustainability />
        <Service />
        <HowItWorks howItWorks_index={howItWorks_index}/>
        <Pricing/>
        <AboutUs />
        <News />
        <ProvideService services={ServiceList}/>
        <Faqs />
        <Help address1="3980 N Collins St #146" address2="Arlington, TX 76005" contact={contact}/>
        <Submit />
      </div>
    )
  };
};

function mapStateToProps(state) {
  return {

  }
}

export default connect(mapStateToProps, {
  toggleRequestModal,
  toggleHomeModal,
  getPhoneNumber
})(withRouter(HomePage));