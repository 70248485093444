import { CONTACT, CONTACT_FAIL, CONTACT_SUCCESS } from '../Type';

const INITIAL = {
    contact_loading: false,
    contact_success: false,
    contact_msg: '',
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case CONTACT: {
            return {
              ...state,
              contact_loading: true,
              contact_success: false,
              contact_msg: '',
            }
          }
          case CONTACT_SUCCESS: {
            return {
              ...state,
              contact_loading: false,
              contact_success: true,
              contact_msg: action.data.msg,
            }
          }
          case CONTACT_FAIL: {
            return {
              ...state,
              contact_loading: false,
              contact_success: false,
              contact_msg: action.data.msg,
            }
          }

        default:
            return state;
    }
}