import { all, fork } from 'redux-saga/effects';

import { watchGetLocatonData } from './Location';
import { watchGetCityList } from './City';
import { watchGetFooterData } from './Footer';
import { watchGetLanding1Data } from './Landing';
import { watchGetAllReviews } from './Review';
import { watchGetAllPosts } from './Post';
import { watchSignIn, watchSignOut, watchSignUp, watchForgotPassword, watchResetPassword } from './Auth';
import { watchUpdatePlan, watchGetPlan } from './Plan';
import { watchGetPricing } from './Pricing';
import { watchContact } from './Contact';
import { watchZipcodeavailable, watchRequestpickup, watchRequestNewZip } from './Pickup';


export default function* rootSaga() {
    yield all([
        fork(watchGetLocatonData),
        fork(watchGetCityList),

        fork(watchGetFooterData),


        fork(watchGetLanding1Data),
        
        fork(watchGetAllReviews),
        fork(watchGetAllPosts),

        fork(watchSignIn),
        fork(watchSignOut),
        fork(watchSignUp),
        fork(watchForgotPassword),
        fork(watchResetPassword),

        fork(watchUpdatePlan),
        fork(watchGetPlan),

        fork(watchGetPricing),

        fork(watchContact),

        fork(watchZipcodeavailable),
        fork(watchRequestpickup),
        fork(watchRequestNewZip),       
    ]);
}