import React from 'react';
import './News.scss';
import telegram_news from '../../../Assets/Home/News/telegram_news.png';
import CBS_news from '../../../Assets/Home/News/CBS_news.png';
import abc_news from '../../../Assets/Home/News/abc_news.png';
import NBC_news from '../../../Assets/Home/News/NBC_news.png';
import app_store from '../../../Assets/Home/News/app_store.png';
import google_play from '../../../Assets/Home/News/google_play.png';
import car from '../../../Assets/Home/News/car.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IOS_APP_LINK, ANDROID_APP_LINK } from "../../../Constant";

const NewsList =[
    telegram_news, CBS_news, abc_news, NBC_news
]
class News extends React.Component {

    gotoApple = () => {
        window.open(IOS_APP_LINK);
    };

    gotoPlayStore = () => {
        window.open(ANDROID_APP_LINK);
    };

    render() {
        
        return (
            <div className="news-component">
                <div className="container">
                    <div className="row">
                        {NewsList.map((data, index)=> (
                            <div className="col-lg-3 v-c h-c" key={index}>
                                <img src={data} alt="logo" className="news-logo" />
                            </div>
                        ))}
                    </div>
                    <div className="row down-app-link">
                            <div className="col-lg-6 left-area">
                                <h1>Download the app</h1>
                                <p>Over <strong>5000</strong> happy customers in DFW!</p>
                                <div className="app-list-container">
                                    <div className="app" onClick={()=>this.gotoApple()}>
                                        <img src={app_store} alt="app-stores" />
                                    </div>
                                    <div className="app google-play-store" onClick={()=>this.gotoPlayStore()}>
                                        <img src={google_play} alt="app-stores" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 right-area v-c h-c">
                                <img src={car} alt="car" />
                            </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(News));