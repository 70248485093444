import React from 'react';
import './TermsPage.scss';
import { Privacy, Term } from './infos';
import { Cleaning } from '../../Components';
import { Helmet } from 'react-helmet';

function innerFunc(item) {
    return {__html: item.content}
}

export default class TermsPage extends React.Component { 
    render() {
        const title = "Term - YellowBag";
        const description ="Read yellowbag terms of services"

        return (
            <div className="siteinfo-page">
                <Helmet>
                    <title>{title}</title>
                    <meta name="title" content={title} />
                    <meta name="description" content={description} />
                    <meta property="type" content="website" />
                    <meta property="url" content={window.location.href}/>
                    <meta property="image" content="https://yellowbagcleaners.com/yellowbag.png" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content={title} />
                    <meta itemprop="description" content={description} />
                    <meta itemprop="image" content="https://yellowbagcleaners.com/yellowbag.png" />

                    {/* <!-- Facebook Meta Tags --> */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content="https://yellowbagcleaners.com/yellowbag.png" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={title}/>
                    <meta name="twitter:description" content={description}/>
                    <meta name="twitter:image" content="https://yellowbagcleaners.com/yellowbag.png"/>
                </Helmet>
                <div className="container siteinfo-container">
                    <div className="title">Terms of Use</div>
                    <div className="siteinfo-list shadow-object">
                        {
                            Term.map((item, index) => {
                                return (
                                    <div className="siteinfo" key={index}>
                                        <div className="siteinfo-title">{item.title}</div>
                                        <div className="siteinfo-content" dangerouslySetInnerHTML={innerFunc(item)}></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="title">Privacy</div>
                    <div className="siteinfo-list shadow-object">
                        {
                            Privacy.map((item, index) => {
                                return (
                                    <div className="siteinfo" key={index}>
                                        <div className="siteinfo-title">{item.title}</div>
                                        <div className="siteinfo-content" dangerouslySetInnerHTML={innerFunc(item)}></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <Cleaning />
                </div>
            </div>
        );
    }
}