import React from 'react';
import './BlogListPage.scss';
import { BlogStartArea, ArticlePreview, Cleaning } from '../../Components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllPosts, toggleHomeModal } from "../../Redux/Actions";
import { Helmet } from 'react-helmet';

const categories = [
    'All', 'Home', 'Care', 'Community', 'Yellowbag', 'Organization',
];

class BlogListPage extends React.Component {
    state = {
        selected_category: 'All',
        categoryToShow: 6,
        filteredArticles: [],
        articleToShow: 3,
        categories: [],
    }

    componentDidMount() {
        this.props.getAllPosts();
        setTimeout(function() {
            this.props.toggleHomeModal(true);
        }.bind(this), 5*60*1000)
    }

    componentDidUpdate(prevProps, PrevState) {
        if (prevProps.post_list !== this.props.post_list && this.props.post_list) {
          this.prepareBlogData(this.props.post_list);
        }
    }

    prepareBlogData(blogs) {
        if (blogs && blogs.length) {
          const category = ["All"];
          blogs.forEach((element) => {
            if (!category.includes(element.topic.name)) {
              category.push(element.topic.name);
            }
          });
          this.setState({
            filteredArticles: blogs,
          });
          this.setState({ categories: category, Articles: blogs });
        }
    }

    changeCategory = (category) => {
        if (this.state.selected_category !== category) {
          const listToShow =
            category === "All"
              ? this.state.Articles
              : this.state.Articles.filter((item) => item.topic.name === category);
          this.setState({
            selected_category: category,
            filteredArticles: listToShow,
            articleToShow: 3,
          });
        }
    };

    navigateToArticle = (url) => {
        this.props.history.push(`/blog/${url}`);
    }

    render() {
        const { selected_category, categoryToShow, filteredArticles, articleToShow, categories } = this.state;
        const remainingCategory = categories.length - categoryToShow;
        const remainingArticle = filteredArticles.length - articleToShow;
        const title = "The YellowBag Blog";
        const description = "The latest on for all things dry cleaning, laundry, becoming a yellowbag partner, and community news"
        return (
            <div className="bloglist-page">
                <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href}/>
                <meta property="image" content="https://yellowbagcleaners.com/Bag.png" />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={title} />
                <meta itemprop="description" content={description} />
                <meta itemprop="image" content="https://yellowbagcleaners.com/Bag.png" />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://yellowbagcleaners.com/Bag.png" />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={description}/>
                <meta name="twitter:image" content="https://yellowbagcleaners.com/Bag.png"/>
            </Helmet>

                <BlogStartArea />
                <div className="container bloglist-container">
                    <div className="categories-area">
                        <h1>Categories</h1>
                        <div className="category-list">
                            {categories.map((item, index) => {
                                return (index < categoryToShow) ? (
                                    <div className={`category ${selected_category === item ? 'selected' : ''}`} key={index} onClick={e => this.changeCategory(item)}> {item}</div>
                                ) : (<div style={{display: 'none'}} key={index} />)
                            })}
                            {remainingCategory > 0 && <div className="category category_plus" onClick={e => this.setState({categoryToShow: categoryToShow + 6})}>+{remainingCategory} categories</div>}
                        </div>
                    </div>
                    <div className="article-area">
                        {filteredArticles.map((item, index) => {
                            return (index >= articleToShow) ? (<div style={{display: 'none'}} key={index} />) : 
                            (index === 0) ? (
                                <div className="row article-row first-row" key={index}>
                                    <div className="col-lg-12">
                                        <ArticlePreview data={item} first onClick={e => this.navigateToArticle(item.url)}/>
                                    </div>
                                </div>
                            ) : (index % 2 === 1) ? (
                                <div className="row article-row" key={index}>
                                    <div className="col-lg-6">
                                        <ArticlePreview data={filteredArticles[index]} onClick={e => this.navigateToArticle(item.url)}/>
                                    </div>
                                    <div className="col-lg-6 second-col">
                                        {filteredArticles[index + 1] && <ArticlePreview data={filteredArticles[index + 1]} onClick={e => this.navigateToArticle(item.id)}/> }
                                    </div>
                                </div>
                            ) : (<div style={{display: 'none'}} key={index} />)
                        })}
                    </div>
                    {remainingArticle > 0 && <div className="btn-area">
                        <button className="btn-2 primary-btn load-more" onClick={e => this.setState({articleToShow: articleToShow + 2})}>Load More</button>
                    </div>}
                </div>
                <Cleaning />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        post_list: state.Post.post_list
    };
  }
  
  export default connect(mapStateToProps, { getAllPosts, toggleHomeModal })(
    withRouter(BlogListPage)
  );