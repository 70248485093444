import React, { useEffect } from 'react';
import './App.scss';
import { Header, Footer, ServiceModal, HomeModal, AppStoreLinkModal, VideoModal, SignOut } from './Components';
import { 
  HomePage, LandingPage1, LocationPage, CitiesPage, LandingPage2, LandingPage3, PickupPage, WelcomePage, 
  TermsPage, BlogListPage, BlogArticlePage
} from './Pages';
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader'
import { EventEmitter } from './Utils/events';
import { css } from "@emotion/react";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import _ from 'lodash'

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class App extends React.Component {
  state = {
    isBlur: false,
    isSidebarOpen: true,
    isSidebarPrevOpen: true,
    isResize: false,
    loading: false
  }  

  static getDerivedStateFromProps(props, state) {
    return {
      isBlur:  props.isModalOpen 
    }
  }

  render() {
    const { isBlur, loading } = this.state;
    EventEmitter.subscribe('isLoading', (event) => this.setState({loading: event}));

    if (isBlur) {
      if (document.body) {
        const body = document.body
        body.classList.add('modal-open');
      }
    } else {
      if (document.body) {
        const body = document.body
        body.classList.remove('modal-open');
      }
    }

    return (
      <BrowserRouter>
        <ScrollToTop />
        <div className="App">
          <div id="main" className={`${isBlur? 'blur': ''}`}>
            <Header />
            <div className="content">
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/on-demand-dry-cleaning&laundry-services" component={LandingPage1} />
                <Route exact path="/top-rank-dry-cleaning-and-laundry-services" component={LandingPage2} />
                <Route exact path="/college-services" component={LandingPage3} />
                <Route exact path="/locations" component={LocationPage} />
                <Route exact path="/service-locations" component={CitiesPage} />
                <Route exact path="/locations/:city" component={LocationPage} />
                <Route exact path="/free-pickup" component={PickupPage} />
                <Route exact path="/welcome" component={WelcomePage} />
                <Route exact path="/blog" component={BlogListPage} />
                <Route exact path="/blog/:url" component={BlogArticlePage} />
                <Route exact path="/terms" component={TermsPage} />
              </Switch>
            </div>
            <Footer />
            <ServiceModal />
            <AppStoreLinkModal />
            <HomeModal />
            <VideoModal />
          </div>
        </div>
        <div className={loading ? "overlay-loader" : "d-none"}>
          <BeatLoader
            css={override}
            size={30}
            color={"#FFF"}
            loading={loading}
          />
        </div>
        <NotificationContainer />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isModalOpen: state.Modal.isServiceModalOpen,    
  }
}

export default connect(mapStateToProps, {})(App);
