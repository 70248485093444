import React from 'react';
import './ServiceStart.scss';
import check_icon from '../../../Assets/Home/Service/check_icon.png';
import delivery_img from '../../../Assets/Home/Start/delivery_img.png';
import delivery_img_mobile from '../../../Assets/Home/Start/delivery_img_mobile.png';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { zipcodeavailable } from '../../../Redux/Actions';
import Helper from '../../../Utils/Helper';
import _ from 'lodash'

const ServiceList =['Free pick up & delivery', '24/7 kiosk storefront', 'Smart lockers'];

class ServiceStart extends React.Component {

    state = {
        zipcode: ''
    }

    componentDidUpdate(prevProps) {
        const {zipcode} = this.state;

        if (prevProps.zipcodeavailable_loading && !this.props.zipcodeavailable_loading) {
            Helper.hideSpinner();

            if(this.props.zipcodeavailable_success){
                this.props.history.push({pathname: "/free-pickup", zipcode})
            } else {
                Helper.showToast('error', 3000, this.props.zipcodeavailable_msg);
                this.props.history.push({pathname: "/free-pickup", zipcode: 'error'})
            }
        }
    }

    freePickUp(){
        const {zipcode} = this.state;
        if(zipcode){
            Helper.showSpinner();
            this.props.zipcodeavailable(this.state.zipcode);
        } else {
            Helper.showToast('error', 3000, "Please insert zip code!");
        }
    }
    
    render() {
        const { title } = this.props;
        return (
            <div className="servicestart-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>{title}</h1>
                            {ServiceList.map((item, index)=> (
                                <div className="service-item v-c mt-2" key={index}>
                                    <img src={check_icon} alt="check-icon" className="check-icon" />
                                    <p>{item}</p>
                                </div>
                            ))}
                            <div className="zip-code-container">
                                <input className="custom-input"
                                    type="number"
                                    placeholder="What is your zipcode"
                                    onChange={e => this.setState({zipcode: e.target.value})} 
                                />
                                <div className="shadow-object box-item v-c h-c btn" onClick={()=>this.freePickUp()}>Get Free Dry Cleaning</div>
                            </div>
                        </div>
                        <div className="col-lg-6 right-area show-web">
                            <img src={delivery_img} alt="delivery_img" className="delivery_img" />
                        </div>
                        <div className="col-lg-6 right-area show-mobile mt-4">
                            <img src={delivery_img_mobile} alt="delivery_img_mobile" className="delivery_img" />
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        zipcodeavailable_loading: state.Pickup.zipcodeavailable_loading,
        zipcodeavailable_success: state.Pickup.zipcodeavailable_success,
        zipcodeavailable_msg: state.Pickup.zipcodeavailable_msg,
    }
}

export default connect(mapStateToProps, {
    zipcodeavailable
})(withRouter(ServiceStart));
