import { 
  AUTH_SIGN_IN, AUTH_SIGN_IN_FAIL, AUTH_SIGN_IN_SUCCESS, AUTH_SIGN_OUT, AUTH_SIGN_OUT_SUCCESS, AUTH_SIGN_OUT_FAIL, AUTH_SIGN_UP, AUTH_SIGN_UP_SUCCESS, AUTH_SIGN_UP_FAIL, 
  AUTH_FORGOT_PASSWORD, AUTH_FORGOT_PASSWORD_SUCCESS, AUTH_FORGOT_PASSWORD_FAIL, AUTH_RESET_PASSWORD, AUTH_RESET_PASSWORD_SUCCESS, AUTH_RESET_PASSWORD_FAIL, AUTH_UPDATE_PLAN,
} from '../Type';
import { REHYDRATE } from 'redux-persist';

const INITIAL = {
  sign_in_success: false,
  token: '',
  user: {},
  sign_in_error_msg: '',
  sign_in_time: 0,
  sign_in_loading: false,
  sign_out_error_msg: '',

  plan_id: null,
  pay_per: null,

  signup_success: false,
  signup_error_msg: '',
  signup_loading: false,

  forgot_password_success: false,
  forgot_password_error_msg: '',
  forgot_password_loading: false,

  reset_password_success: false,
  reset_password_error_msg: '',
  reset_password_loading: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case REHYDRATE: {
      if (!action.payload || !action.payload.Auth) return state;
      const { Auth } = action.payload;
      const { token, user, sign_in_time, sign_in_success, plan_id, pay_per } = Auth;
      let diff = Date.now() - sign_in_time;
      let seconds = Math.floor(diff / 1000);
      let checkAuth = seconds > 3600 || !sign_in_success;
      checkAuth ? sessionStorage.clear() : sessionStorage.setItem('token', token);
      return checkAuth ? {
        ...state,
        sign_in_success: false,
        token: '',
        user: {},
        sign_in_error_msg: '',
        sign_in_time: 0,
        sign_out_error_msg: '',
        plan_id: null,
        pay_per: null,
      } : {
        ...state,
        token, user, plan_id, pay_per,
        sign_in_time: Date.now(),
        sign_in_success: true,
        sign_in_error_msg: '',
        sign_out_error_msg: '',
      }
    }
    case AUTH_SIGN_IN: {
      return {
        ...state,
        sign_in_success: false,
        sign_in_loading: true,
        token: '',
        user: {},
        sign_in_error_msg: '',
        sign_in_time: 0,
        plan_id: null,
        pay_per: null,
      }
    }
    case AUTH_SIGN_IN_FAIL: {
      return {
        ...state,
        sign_in_success: false,
        sign_in_loading: false,
        token: '',
        user: {},
        sign_in_error_msg: action.data.msg,
        sign_in_time: 0,
      }
    }
    case AUTH_SIGN_IN_SUCCESS: {
      const { token, user } = action.data;
      sessionStorage.setItem('token', token);
      return {
        ...state,
        sign_in_success: true,
        sign_in_loading: false,
        token: token,
        user: user,
        sign_in_error_msg: '',
        sign_in_time: Date.now(),
      }
    }
    case AUTH_SIGN_OUT: {
      return {
        ...state,
        sign_out_error_msg: ''
      }
    }
    case AUTH_SIGN_OUT_SUCCESS: {
      sessionStorage.clear();
      return {
        ...state,
        sign_in_success: false,
        token: '',
        user: {},
        sign_in_error_msg: '',
        sign_in_time: 0,
        sign_out_error_msg: '',
        plan_id: null,
        pay_per: null,
      }
    }
    case AUTH_SIGN_OUT_FAIL: {
      return {
        ...state,
        sign_out_error_msg: action.data.msg,
      }
    }
    case AUTH_SIGN_UP: {
      return {
        ...state,
        signup_loading: true,
        signup_success: false,
        signup_error_msg: '',
        token: '', 
        user: {},
        sign_in_time: 0,
        plan_id: null,
        pay_per: null,
      }
    }
    case AUTH_SIGN_UP_SUCCESS: {
      const { token, user } = action.data;
      sessionStorage.setItem('token', token);
      return {
        ...state,
        signup_loading: false,
        signup_success: true,
        signup_error_msg: '',
        token: token,
        user: user,
        sign_in_time: Date.now()
      }
    }
    case AUTH_SIGN_UP_FAIL: {
      return {
        ...state,
        signup_loading: false,
        signup_success: false,
        signup_error_msg: action.data.msg,
      }
    }
    case AUTH_FORGOT_PASSWORD: {
      return {
        ...state,
        forgot_password_loading: true,
        forgot_password_success: false,
        forgot_password_error_msg: ''
      }
    }
    case AUTH_FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgot_password_loading: false,
        forgot_password_success: true,
        forgot_password_error_msg: ''
      }
    }
    case AUTH_FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        forgot_password_loading: false,
        forgot_password_success: false,
        forgot_password_error_msg: action.data.msg,
      }
    }
    case AUTH_RESET_PASSWORD: {
      return {
        ...state,
        reset_password_loading: true,
        reset_password_success: false,
        reset_password_error_msg: ''
      }
    }
    case AUTH_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        reset_password_loading: false,
        reset_password_success: true,
        reset_password_error_msg: ''
      }
    }
    case AUTH_RESET_PASSWORD_FAIL: {
      return {
        ...state,
        reset_password_loading: false,
        reset_password_success: false,
        reset_password_error_msg: action.data.msg,
      }
    }
    case AUTH_UPDATE_PLAN: {
      const { plan_id, pay_per } = action.data;
      return {
        ...state,
        plan_id: plan_id, 
        pay_per: pay_per
      }
    }
    default:
        return state;
  }
}