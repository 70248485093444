import React from 'react';
import './Request.scss';
import phone from '../../Assets/Home/Help/phone.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg'
import close_icon from '../../Assets/Icons/close_icon.svg';
import up from '../../Assets/Icons/up.svg';
import dot from '../../Assets/Icons/dot.svg';
import { toggleRequestModal } from '../../Redux/Actions';

class Request extends React.Component {

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
        }
    }

    closeRequestModal(){
        sessionStorage.setItem("doneRequest", true);

        this.props.toggleRequestModal(false);
    }

    freePickUp(){
        this.props.history.push("/free-pickup")
    }

    render() {
        let { isOpen } = this.state;
        return (
            <div className="request-component show-web" style={{display: !isOpen && 'none'}}>
                <div className="container request-cotainer">
                    <div className="request-content">
                        <ReactSVG src={ up } className='up'/>
                        <div className="close-btn" onClick={ () => this.closeRequestModal() }> 
                            <ReactSVG src={ close_icon } className='icon-close'/>
                        </div>
                        <h1 className="text-center">Request FREE Dry Cleaning & And Get:</h1>
                        <div>
                            <div className="item">
                                <ReactSVG src={ dot } className='dot'/>
                                <p>FREE lifetime pick-up and delivery</p>  
                            </div>
                            <div className="item">
                                <ReactSVG src={ dot } className='dot'/>
                                <p>FREE dry cleaning - first 2 of 10 items minimum</p>
                            </div>
                            <div className="item">
                                <ReactSVG src={ dot } className='dot'/>
                                <p>Garments get cleaned with eco friendly chemicals</p>
                            </div>
                            <div className="item">
                                <ReactSVG src={ dot } className='dot'/>
                                <p>Experience yellowbag promise: - Re do guarantee if you’re not 100% satisfied</p>
                            </div>
                        </div>
                        <div className="request-btn btn v-c h-c mt-4" onClick={()=>this.freePickUp()}>Request Free Pick-Up</div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isRequestModalOpen,
    }
}

export default connect(mapStateToProps, {toggleRequestModal})(withRouter(Request));
