import React from 'react';
import './CitiesPage.scss';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCityList } from "../../Redux/Actions";
import Helper from '../../Utils/Helper';
import _ from 'lodash'

const Alphabet = [
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P",  "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
]

class CitiesPage extends React.Component {
  state ={
    current_index: 0,
  }

  componentDidMount(){
    Helper.showSpinner();
    this.props.getCityList('A');
  } 

  componentDidUpdate(prevProps) {
    if (prevProps.get_cityList_loading && !this.props.get_cityList_loading) {
      if(this.props.get_cityList_success){            
        Helper.hideSpinner();        
      }
    }
  }

  selectIndex = (item, index) => {
    this.setState({current_index: index})
    this.props.getCityList(item);
    Helper.showSpinner();
  }

  selectCity = (item) => {
    const cityLink = item.link.replace(/\s+/g, '-');
    window.open(`${window.location.origin}${cityLink}`);
  }

  render() {
    const { CityList } = this.props;
    const { current_index } = this.state;
    return (
      <>
        <div className="city-page">
          <div className="container"> 
            <h1>Service Cities</h1>
            <div className="alphabet-content">
              {Alphabet.map((item, index)=>(
                <div className={`alpha-item ${current_index === index? "active" : ""}`} key={index} onClick={()=>this.selectIndex(item, index)}>
                  {item}
                </div>
              ))}
            </div>
            {!_.isEmpty(CityList) && <div className="city-list">
              {CityList.map((item, index)=> (
                <div className="city-item v-c mr-5" key={index} onClick={()=>this.selectCity(item)}>
                  <div className="v-c h-c mr-3">•</div>
                  <div>{item.name}</div>                  
                </div>
              ))}
            </div>}
          </div>
        </div>
      </>
    )
  };
};

const mapStateToProps = (state) => ({  
  CityList: state.City.CityList,
  get_cityList_loading: state.City.get_cityList_loading,
  get_cityList_success: state.City.get_cityList_success,
});

export default connect(mapStateToProps, {
  getCityList
})(withRouter(CitiesPage));