import React from 'react';
import './LandingPage1.scss';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { 
  Request,
  Landing1Start,
  WhyUs,
  Convenience, 
  Quality, 
  Sustainability, 
  Service, 
  HowItWorks,
  Pricing,
  AboutUs,
  News,
  ProvideService,
  Cleaning,
  Faqs,
} from '../../Components';
import { toggleRequestModal, toggleHomeModal, getPhoneNumber, getLanding1Data } from '../../Redux/Actions';
import { ServiceList } from "../HomePage/ServiceList";
import Helper from '../../Utils/Helper';
import _ from 'lodash'
const publicIp = require('public-ip');

class LandingPage1 extends React.Component {
  state ={
    howItWorks_index: 0
  }

  async componentDidMount (){
    Helper.showSpinner();

    const doneRequest = sessionStorage.getItem("doneRequest");
    if(!doneRequest){
      this.props.toggleRequestModal(true);
    }

    const doneHomeModal = sessionStorage.getItem("doneHomeModal");
    if(!doneHomeModal){
      setTimeout(function() {
        this.props.toggleHomeModal(true);
      }.bind(this), 5*60*1000)
    }

    
    var my_ip = await publicIp.v4()
    if(my_ip){
      this.props.getLanding1Data(my_ip);
    }
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.get_landing1_data_loading && !this.props.get_landing1_data_loading) {
      if(this.props.get_landing1_data_success){
        Helper.hideSpinner();
        this.props.getPhoneNumber(this.props.Landing1Data.phone);
      }
    }
  }

  selectIndex = (e) => {
    this.setState({howItWorks_index: e})
  }

  render() {
    const { howItWorks_index } = this.state;
    const { Landing1Data } = this.props;
    const title =  "Ranked #1 For On-Demand Dry Cleaning & Laundry Services - YellowBag"
    const description = "Free lifetime pick-up and delivery for all your dry cleaning and laundry needs. New customers also get 2 of 10 items minimum dry clean for free. See if you qualify"
    return (
      <>
        {Landing1Data && <div className="landing-page">
          <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta property="type" content="website" />
            <meta property="url" content={window.location.href}/>
            <meta property="image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={title} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content="https://yellowbagcleaners.com/yellowbag.png"/>
          </Helmet> 

          <Request />
          <Landing1Start city={Landing1Data.city}/>
          <WhyUs />
          <Convenience selectIndex={(e)=> this.selectIndex(e)}/>
          <Quality />
          <Sustainability />
          <Service />
          <HowItWorks howItWorks_index={howItWorks_index}/>
          <Pricing city={Landing1Data.city}/>
          <AboutUs />
          <News />
          <ProvideService services={ServiceList}/>
          <Cleaning />
          <Faqs />
        </div>}
      </>
    )
  };
};

function mapStateToProps(state) {
  return {
    Landing1Data: state.Landing.Landing1Data,
    get_landing1_data_loading: state.Landing.get_landing1_data_loading,
    get_landing1_data_success: state.Landing.get_landing1_data_success,
  }
}

export default connect(mapStateToProps, {
  toggleRequestModal,
  toggleHomeModal,
  getPhoneNumber,
  getLanding1Data
})(withRouter(LandingPage1));