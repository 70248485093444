import { UPDATE_PLAN, UPDATE_PLAN_SUCCESS, UPDATE_PLAN_FAIL } from '../Type';

const INITIAL = {
  update_success: false,
  update_error_msg: '',
  update_loading: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case UPDATE_PLAN: {
            return {
                ...state,
                update_success: false,
                update_error_msg: '',
                update_loading: true,
            }
        }
        case UPDATE_PLAN_SUCCESS: {
            return {
                ...state,
                update_success: true,
                update_error_msg: '', 
                update_loading: false,
            }
        }
        case UPDATE_PLAN_FAIL: {
            return {
                ...state,
                update_success: false,
                update_error_msg: action.data.msg,
                update_loading: false
            }
        }
        default:
            return state;
    }
}