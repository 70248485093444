import React from 'react';
import './Sustainability.scss';
import sustain_logo from '../../../Assets/Home/Sustainability/sustain_logo.png';
import sustain_logo_mobile from '../../../Assets/Home/Sustainability/sustain_logo_mobile.png';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const ConvenienceList =[
    {num: '01', title: '24/7 Kiosk powered storefront', description: 'Drop off and pick up your garment at any time'},
    {num: '02', title: 'FREE Pick Up & Delivery', description: 'We pick up and deliver your clean garment for no additional...'},
    {num: '03', title: 'Quick Access Smart Lockers ', description: 'We place high-tech smart lockers at apartments, office...'},
]

class Sustainability extends React.Component {

    render() {
        return (
            <div className="sustainability-component" id="section-sustainability">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 order-lg-first order-second left-area">
                            <img src={sustain_logo} alt="sustain-logo" className="sustain-logo show-web" />                   
                            <img src={sustain_logo_mobile} alt="sustain-logo" className="sustain-logo show-mobile" />                   
                        </div>
                        <div className="col-lg-5 order-lg-second order-first right-area ">
                            <h1>Sustainability</h1>
                            <p>We’re 100% eco-friendly. We only use chemicals that are good for your garments and for the environment</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Sustainability));
