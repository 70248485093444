import React from 'react';
import './WelcomeFooter.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class WelcomeFooter extends React.Component {

    completeRequest(){
        window.open("https://account.mydrycleaner.com/YellowBagTX");
    }

    render() {
        return (
            <div className="welcome-footer-component">
                <div className="container">
                    <div className="welcome-footer-content">
                        <p>Not interested in downloading the mobile app, use the button below to complete the pick-up request on our website</p>
                        <div className="request-btn btn v-c h-c shadow-object"  onClick={()=>this.completeRequest()}>Complete Request On Computer</div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(WelcomeFooter));
