import {
    GET_FOOTER_DATA, GET_FOOTER_DATA_SUCCESS, GET_FOOTER_DATA_FAIL,
} from '../Type';
import { takeLatest, put } from 'redux-saga/effects';
import {
    getCityList as getFooterDataApi
} from '../../Api';

function* getFooterData(payload){
    try {
        const result = yield getFooterDataApi(payload.page)
        if (result) {
            yield put({ type: GET_FOOTER_DATA_SUCCESS, data: result })
        } else {
            yield put({ type: GET_FOOTER_DATA_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_FOOTER_DATA_FAIL, data: [] })
    }
}

export function* watchGetFooterData(){
    yield takeLatest(GET_FOOTER_DATA, getFooterData)
}