import React from 'react';
import './Steps.scss';
import mail_box from '../../../Assets/Home/Help/mail_box.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

let StepList = [
    {id: 1, title: 'Click link to download app'},
    {id: 2, title: 'Create your free account'},
    {id: 3, title: 'Request FREE pick-up'},
    {id: 4, title: 'Enjoy a lifetime of free pick-up and delivery'},
]
class Steps extends React.Component {

    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        if(query.get('type') == 'College Plan'){
            StepList = [
                {id: 1, title: 'Click link to download app'},
                {id: 2, title: 'Create your free account'},
                {id: 3, title: 'Click on profile once log into the app'},
                {id: 4, title: 'Add billing info'},
                {id: 5, title: 'Click on manage subscription and select your desired college plan'},
                {id: 6, title: 'A member of our staff will contact you facilitate your first pick'},
            ]
        }
    }
    render() {
        return (
            <div className="steps-component">
                <div className="container">
                    <h1 className="text-center">Steps</h1>
                    <div className="step-content">
                        {StepList.map((item, index)=> (
                            <div className="step-item v-c" key={index}>
                                <div className="step-num v-c h-c">{item.id}</div>
                                <div className="step-title">{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Steps));
