import { SERVICE_MODAL_TOGGLE, HOME_MODAL_TOGGLE, REQUEST_MODAL_TOGGLE, TUTOR_MODAL_TOGGLE, VIDEO_MODAL_TOGGLE, APP_STORE_LINK_MODAL_TOGGLE } from '../Type';

export const toggleServiceModal = (isOpen, info = {}) => {
  return {
    type: SERVICE_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleHomeModal = (isOpen) => {
  return {
    type: HOME_MODAL_TOGGLE,
    data: { isOpen }
  }
}

export const toggleRequestModal = (isOpen) => {
  return {
    type: REQUEST_MODAL_TOGGLE,
    data: { isOpen }
  }
}

export const toggleVideoModal = (isOpen, info = null) => {
  return {
    type: VIDEO_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleTutorModal = (isOpen, info = null, rect = null) => {
  return {
    type: TUTOR_MODAL_TOGGLE,
    data: { isOpen, info, rect }
  }
}

export const toggleAppStoreLinkModal = (isOpen) => {
  return {
    type: APP_STORE_LINK_MODAL_TOGGLE,
    data: { isOpen }
  }
}


