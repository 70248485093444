import { GET_CITY_LIST, GET_CITY_LIST_SUCCESS, GET_CITY_LIST_FAIL } from '../Type';

const INITIAL = {
  CityList: null,
  get_cityList_loading: false,
  get_cityList_success: false
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_CITY_LIST: {
      return {
        get_cityList_loading: true,
        get_cityList_success: false,
      }
    }
    case GET_CITY_LIST_FAIL: {
        return {
          CityList: null,
          get_cityList_loading: false,
          get_cityList_success: false
        }
    }
    case GET_CITY_LIST_SUCCESS: {
      return {
        CityList: action.data,
        get_cityList_loading: false,
        get_cityList_success: true
      }
    }

    default:
        return state;
  }
}