import React from 'react';
import './LandingPage2.scss';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { 
  Request,
  HomeStart,
  WhyUs,
  Convenience, 
  Quality, 
  Sustainability, 
  Service, 
  HowItWorks,
  AboutUs,
  News,
  ProvideService,
  Cleaning,
} from '../../Components';
import { ServiceList } from "../HomePage/ServiceList";
import { toggleRequestModal, toggleHomeModal, getPhoneNumber } from '../../Redux/Actions';


class LandingPage2 extends React.Component {

  state ={
    howItWorks_index: 0
  }

  componentDidMount(){
    const doneRequest = sessionStorage.getItem("doneRequest");
    if(!doneRequest){
      this.props.toggleRequestModal(true);
    }

    const doneHomeModal = sessionStorage.getItem("doneHomeModal");
    if(!doneHomeModal){
      setTimeout(function() {
        this.props.toggleHomeModal(true);
      }.bind(this), 5*60*1000)
    }

    this.props.getPhoneNumber(process.env.REACT_APP_PHONE);
  }

  selectIndex = (e) => {
    this.setState({howItWorks_index: e})
  }

  render() {
    const { howItWorks_index } = this.state;
    const title =  "YellowBag Cleaners - Dry Cleaning & Laundry Delivered For Free"
    const description = "Free Dry cleaning for new customers ( 2 of 10 items minimum). Free lifetime pick-up & delivery for all your dry cleaning and laundry needs."
    return (
      <div className="landing-page">
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta property="type" content="website" />
            <meta property="url" content={window.location.href}/>
            <meta property="image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={title} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content="https://yellowbagcleaners.com/yellowbag.png"/>
        </Helmet>

        <Request />
        <HomeStart />
        <WhyUs />
        <Convenience selectIndex={(e)=> this.selectIndex(e)}/>
        <Quality />
        <Sustainability />
        <Service />
        <HowItWorks howItWorks_index={howItWorks_index}/>
        <AboutUs />
        <News />
        <ProvideService services={ServiceList}/>
        <Cleaning />
      </div>
    )
  };
};

function mapStateToProps(state) {
  return {

  }
}

export default connect(mapStateToProps, {
  toggleRequestModal,
  toggleHomeModal,
  getPhoneNumber
})(withRouter(LandingPage2));