import React from 'react';
import './PickupPage.scss';
import back_icon from '../../Assets/Icons/back_icon.png';
import { ReactSVG } from 'react-svg';
import { InputBox, SelectBox, PhoneNumberInput } from '../../Components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { zipcodeavailable, requestpickup, requestNewZip } from '../../Redux/Actions';
import Helper from '../../Utils/Helper';
import _ from 'lodash'

const Data = [
    {
        step: 1,
        title: "What is your zipcode",
        state_val: 'zipcode',
        placeholder: 'Type here',
        type: 'number',
        description: 'Free lifetime pick-up and delivery',
        button: 'Continue'
    },
    {
        step: 2,        
        title: "Your name",
        state_val: 'name',
        placeholder: 'Type here',
        description: 'Good news! We offer free lifetime pick-up & delivery in your area',
        button: 'Continue'
    },
    {
        step: 3,        
        title: "Your email",
        state_val: 'email',
        placeholder: 'Type here',
        description: `No junk emails! We're huge on privacy`,
        button: 'Continue'
    },
    {
        step: 4,        
        title: "Your phone number",
        state_val: 'phone',
        placeholder: 'Type here',
        type: 'number',
        description: '',
        button: 'Continue'
    },
    {
        step: 5,
        title: "Your street address",
        state_val: 'address',
        placeholder: 'Type here',
        description: '',
        button: 'Continue'
    },
    {
        step: 6,
        title: "Type of service",
        state_val: 'service_type',
        placeholder: 'What type of service are you requesting?',
        description: '',
        button: 'Done'
    },
    {
        step: 7, 
        title: "Sorry! We're not currently offering free pick-up and delivery in your area. Please enter your email below for use to contact you once the service becomes available in your zipcode.",
        state_val: 'email',
        placeholder: 'Type email address',
        description: ``,
        button: 'Submit'
    },
];


class PickupPage extends React.Component {
    state = {
        step: 1,

        zipcode: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        service_type: ''
    }


    componentWillMount() {
        const { zipcode } = this.props.location;
        if(zipcode && zipcode !== 'error'){
            this.setState({
                zipcode,
                step: 2
            })
        }

        if(zipcode === 'error'){
            this.setState({
                step: 7
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.zipcodeavailable_loading && !this.props.zipcodeavailable_loading) {
            if(this.props.zipcodeavailable_success){
                this.setState({step: 2});
            } else {
                Helper.showToast('error', 3000, this.props.zipcodeavailable_msg);
                this.setState({step: 7});
            }
        }

        if (prevProps.pickup_loading && !this.props.pickup_loading) {
            if(this.props.pickup_success){
                this.props.history.push('/welcome?type='+prevState.service_type);
                Helper.showToast('success', 3000, this.props.pickup_msg);
            } else {
                Helper.showToast('error', 3000, this.props.pickup_msg);
            }
        }

        if (prevProps.request_newZip_loading && !this.props.request_newZip_loading) {
            if(this.props.request_newZip_success){                
                Helper.showToast('success', 3000, this.props.request_newZip_msg);
            } else {
                Helper.showToast('error', 3000, this.props.request_newZip_msg);
            }
        }
    }

    onChange = (field, val) => {
        this.setState({[field]: val});
	}

    moveForward = () => {
        const { step, zipcode, name, email, phone, address, service_type } = this.state;
        switch(step) {
            case 1:
                if(zipcode){
                    this.zipcodeAvailable()
                } else {
                    Helper.showToast('error', 3000, "Please insert zip code!");
                }
                break;
            case 2:
                if(name){
                    this.setState({step: step + 1});
                } else {
                    Helper.showToast('error', 3000, "Please insert your name!");
                }
                break;
            case 3:
                if(email){
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if(!re.test(email.toLowerCase())){
                        Helper.showToast('error', 3000, "Please input valid email information!");
                    } else {
                        this.setState({step: step + 1});
                    }
                } else {
                    Helper.showToast('error', 3000, "Please insert your email!");
                }
                break;
            case 4:
                if(phone){
                    this.setState({step: step + 1});
                } else {
                    Helper.showToast('error', 3000, "Please insert your phone!");
                }
                break;
            case 5:
                if(address){
                    this.setState({step: step + 1});
                } else {
                    Helper.showToast('error', 3000, "Please insert your address!");
                }
                break;
            case 6:
                if(service_type){
                    this.requestpickup();
                } else {
                    Helper.showToast('error', 3000, "Please select a service!");
                }
                break;
            case 7:
                if(email){
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if(!re.test(email.toLowerCase())){
                        Helper.showToast('error', 3000, "Please input valid email information!");
                    } else {
                        this.requestNewZip();
                    }
                } else {
                    Helper.showToast('error', 3000, "Please insert your email!");
                }
                    break;
            default:
                console.log("next step")
        }
    }

    goBack = () => {
        const { step } = this.state;
        if(step === 1){
            this.props.history.push('/');
        } else {
            this.setState({step: step - 1});
        }
    }

    zipcodeAvailable = () => {
        this.props.zipcodeavailable(this.state.zipcode);
    }

    requestNewZip = () => {
        const { zipcode, email, } = this.state;

        const deploy ={
            name: '',
            email,
            phone: '',
            zipcode,
            address: 'Zipcode not available, email me when available'
        };
        this.props.requestNewZip(deploy);
    }

    requestpickup = () => {
        const { zipcode, name, email, phone, address, service_type } = this.state;
        
        const deploy ={
            name,
            email,
            phone,
            zipcode,
            address,
            service_type
        }
        this.props.requestpickup(deploy);
    }

    renderStep = (Datas) => {
        const { step } = this.state;
        const { zipcodeavailable_loading, pickup_loading, request_newZip_loading} = this.props;
        return Datas.map((info, dataIndex) => step === info.step && <div className="content-area" key={dataIndex}>
            <div className="">
                <h1>{info.title}</h1>
                {info.state_val == 'phone' &&
                    <PhoneNumberInput className="input-box" onChange={(e) => this.onChange(info.state_val, e)}/>
                }
                {info.state_val == 'service_type' &&
                    <SelectBox className="input-box" onChange={(e) => this.onChange(info.state_val, e)} options={['Residential','Commercial','College Plan','Other']} placeholder={info.placeholder}/>
                }
                {info.state_val !== 'phone' && info.state_val !== 'service_type' &&
                    <InputBox className="input-box" type={info.type} placeholder={info.placeholder} value={this.state[info.state_val]} onChange={e => this.onChange(info.state_val, e)} />
                }
                <p>{info.description}</p>
                <div className={`next-step ${step === 7 ? "unavailable-zip" : ""}`}>
                    {step !== 7 && <div className="v-c btn" onClick={()=>this.goBack()}>
                        <img src={back_icon} alt="app-stores" className=""/>
                        <div className="back-text ml-2 ">Back</div>
                    </div>}
                    <div className={`continue-btn btn v-c h-c shadow-object ${(zipcodeavailable_loading || pickup_loading || request_newZip_loading) ? 'disabled' : ''}`} onClick={()=>this.moveForward()}>
                        {(zipcodeavailable_loading || pickup_loading || request_newZip_loading) ? <div className="spinner" /> : info.button}
                    </div>
                </div>
            </div>
        </div>)
    }

    render() {
        return (
            <div className="pickup-page">
                <div className="container">
                    <div className="main-content">
                        {this.renderStep(Data)}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        zipcodeavailable_loading: state.Pickup.zipcodeavailable_loading,
        zipcodeavailable_success: state.Pickup.zipcodeavailable_success,
        zipcodeavailable_msg: state.Pickup.zipcodeavailable_msg,

        pickup_loading: state.Pickup.pickup_loading,
        pickup_success: state.Pickup.pickup_success,
        pickup_msg: state.Pickup.pickup_msg,

        request_newZip_loading: state.Pickup.request_newZip_loading,
        request_newZip_success: state.Pickup.request_newZip_success,
        request_newZip_msg: state.Pickup.request_newZip_msg,
    }
}

export default connect(mapStateToProps, { 
    zipcodeavailable,
    requestpickup,
    requestNewZip
 })(withRouter(PickupPage));