import React from 'react';
import './LandingPage3.scss';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { 
  Request,
  HomeStart,
  WhyUs,
  Convenience, 
  Quality, 
  Sustainability, 
  Service, 
  HowItWorks,
  AboutUs,
  News,
  ProvideService,
  Cleaning,
  Pricing,
} from '../../Components';
import { toggleRequestModal, toggleHomeModal, getPhoneNumber } from '../../Redux/Actions';
import provide1 from '../../Assets/Service/ProvideService/provide1.png';
import provide2 from '../../Assets/Service/ProvideService/provide2.png';
import provide6 from '../../Assets/Service/ProvideService/provide6.png';
import provide1_mobile from '../../Assets/Service/ProvideService/provide1_mobile.png';
import provide2_mobile from '../../Assets/Service/ProvideService/provide2_mobile.png';
import provide6_mobile from '../../Assets/Service/ProvideService/provide6_mobile.png';


class LandingPage3 extends React.Component {

  state ={
    howItWorks_index: 0
  }

  componentDidMount(){
    const doneRequest = sessionStorage.getItem("doneRequest");
    if(!doneRequest){
      this.props.toggleRequestModal(true);
    }

    const doneHomeModal = sessionStorage.getItem("doneHomeModal");
    if(!doneHomeModal){
      setTimeout(function() {
        this.props.toggleHomeModal(true);
      }.bind(this), 5*60*1000)
    }

    this.props.getPhoneNumber(process.env.REACT_APP_PHONE);
  }

  selectIndex = (e) => {
    this.setState({howItWorks_index: e})
  }

  render() {
    const { howItWorks_index } = this.state;
    const title =  "YellowBag Cleaners - Dry Cleaning & Laundry Delivered For Free"
    const description = "Free Dry cleaning for new customers ( 2 of 10 items minimum). Free lifetime pick-up & delivery for all your dry cleaning and laundry needs."
    const ServiceList = [
        {image: provide1, logo_mobile: provide1_mobile, name: 'Wash & Fold', description: "Fill the bag to the top with your activewear, homewear and clothing that don't need pressing. We will clean and neatly fold your items. Your clothes are not mixed with other people clothes. We offer quarterly, six months and yearly subscription with our wash & fold service. You can contant u to learn more.  If you need the items hong, we will need you to provide hangers."},
        {image: provide2, logo_mobile: provide2_mobile, name: 'College Services', description: `We provide wash and folder and dry cleaning services to universities. College is for growth and not for wasting precious time doing laundry. This is time that will yield massive returns if invested in your studies. We provide our service to Universities via weekly pick up delivery in around college campus. Students living on and off campus drop off your dirty garments at designated locations and pick them up perfectly clean within 48hrs.`},
        {image: provide6, logo_mobile: provide6_mobile, name: 'Pick-Up & Delivery Services', description: "No pickup and delivery fees! Yes! You heard that right. All you have to do is enter your zipcode to see if you qualify for our free lifetime pick up and delivery. We pick up your dirty garments and deliver perfectly clean garments. We offer this service for dry cleaning, wash/fold and alterations."},
    ];
    return (
      <div className="landing-page">
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta property="type" content="website" />
            <meta property="url" content={window.location.href}/>
            <meta property="image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={title} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://yellowbagcleaners.com/yellowbag.png" />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content="https://yellowbagcleaners.com/yellowbag.png"/>
        </Helmet>

        <Request />
        <HomeStart />
        <WhyUs />
        <Convenience selectIndex={(e)=> this.selectIndex(e)}/>
        <Quality />
        <Sustainability />
        <Service />
        <HowItWorks howItWorks_index={howItWorks_index}/>
        <AboutUs />
        <News />
        <ProvideService services={ServiceList}/>
        <Pricing city=''/>
        <Cleaning />
      </div>
    )
  };
};

function mapStateToProps(state) {
  return {

  }
}

export default connect(mapStateToProps, {
  toggleRequestModal,
  toggleHomeModal,
  getPhoneNumber
})(withRouter(LandingPage3));