import React from 'react';
import './Landing1Start.scss';
import arrow from '../../../Assets/Home/Start/arrow.png';
import delivery_img from '../../../Assets/Home/Start/delivery_img.png';
import delivery_img_mobile from '../../../Assets/Home/Start/delivery_img_mobile.png';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

class Landing1Start extends React.Component {
    state = {
        zipcode: ''
    }

    freePickUp(){
        const {zipcode} = this.state;
        this.props.history.push({pathname: "/free-pickup", zipcode})
    }

    render() {
        const { city } = this.props;
        return (
            <div className="landing1start-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>#1 On-Demand Dry Cleaning + Wash & Fold Service In {city}</h1>
                            <div className="description">
                                <div className="free-box v-c h-c">FREE</div>
                                <div className="sub-title">DRY CLEANING</div>
                                <p>Your first 2 of 10 items dry cleaned for free</p>

                                <img src={arrow} alt="arrow" className="arrow" />

                                <div className="shadow-object box-item v-c h-c btn" onClick={()=>this.freePickUp()}>Request Free Pick-Up</div>
                            </div>
                        </div>
                        <div className="col-lg-6 right-area show-web">
                            <img src={delivery_img} alt="delivery_img" className="delivery_img" />
                        </div>
                        <div className="col-lg-6 right-area show-mobile mt-4">
                            <img src={delivery_img_mobile} alt="delivery_img_mobile" className="delivery_img" />
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {})(withRouter(Landing1Start));
