import { UPDATE_PLAN, UPDATE_PLAN_SUCCESS, UPDATE_PLAN_FAIL, AUTH_UPDATE_PLAN, GET_PLAN, GET_PLAN_FAIL, GET_PLAN_SUCCESS } from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    updatePlan as updatePlanApi,
    getPlan as getPlanApi,
} from '../../Api';

function* updatePlan(payload){
    try {
        const result = yield updatePlanApi(payload.data);
        if (result.message && result.message.length > 0) {
            yield put({ type: UPDATE_PLAN_FAIL, data: {msg: result.message}})
        } else {
            yield put({ type: UPDATE_PLAN_SUCCESS, data: result})
            yield put({ type: AUTH_UPDATE_PLAN, data: { plan_id: result.data.Plan.id, pay_per: result.data.pay_per }})
        }
    } catch (err) {
        yield put({ type: UPDATE_PLAN_FAIL, data: {msg: 'NET ERROR'} })
    }
}

export function* watchUpdatePlan(){
    yield takeLatest(UPDATE_PLAN, updatePlan)
}

function* getPlan(payload){
    try {
        const result = yield getPlanApi();
        if (result.message && result.message.length > 0) {
            yield put({ type: GET_PLAN_FAIL, data: {msg: result.message}})
        } else {
            yield put({ type: GET_PLAN_SUCCESS, data: result})
            yield put({ type: AUTH_UPDATE_PLAN, data: { plan_id: result.data.Plan.id, pay_per: result.data.pay_per }})
        }
    } catch (err) {
        yield put({ type: GET_PLAN_FAIL, data: {msg: 'NET ERROR'} })
    }
}

export function* watchGetPlan(){
    yield takeLatest(GET_PLAN, getPlan)
}
