import React from 'react';
import './Footer.scss';
import Roland_photo from '../../Assets/Footer/Roland_photo.png';
import Yannick_photo from '../../Assets/Footer/Yannick_photo.png';
import facebook_icon from '../../Assets/Footer/facebook_icon.png';
import instagram_icon from '../../Assets/Footer/instagram_icon.png';
import twitter_icon from '../../Assets/Footer/twitter_icon.png';
import youtube_icon from '../../Assets/Footer/youtube_icon.png';
import pinterest_icon from '../../Assets/Footer/pinterest_icon.png';
import tiktok_icon from '../../Assets/Footer/tiktok_icon.png';
import app_store from '../../Assets/Icons/app_store.png';
import play_store from '../../Assets/Icons/play_store.png';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFooterData } from "../../Redux/Actions";
import { IOS_APP_LINK, ANDROID_APP_LINK } from "../../Constant";

const FounderList = [
    //{name: 'Roland Omene', role: 'TCU Alumni ', photo: Roland_photo},
    {name: 'Yannick Akaraqwe', role: 'Texas Tech Alumni ', photo: Yannick_photo},
];
const SocialLink = [
    {icon: facebook_icon, link: 'https://www.facebook.com/yellowbagcleaners' },
    {icon: instagram_icon, link: 'https://www.instagram.com/yellowbagcleaners/' },
    {icon: twitter_icon, link: 'https://twitter.com/' },
    {icon: youtube_icon, link: 'https://www.youtube.com/channel/UCPGs5AkaZ-5lKaaI5DOWmaA' },
    {icon: pinterest_icon, link: 'https://www.pinterest.com/' },
    {icon: tiktok_icon, link: 'https://www.tiktok.com/' },
]

class Footer extends React.Component {
    state ={
        showMore: false,
        showMoreTxt: 'More ...',
        limit: 10,
    }

    componentDidMount(){        
        this.props.getFooterData('homepage');
    } 

    componentDidUpdate(prevProps) {
        if (prevProps.get_footer_data_loading && !this.props.get_footer_data_loading) {
          if(this.props.get_fotter_data_success){            
            
          }
        }
    }

    hideFooter = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/free-pickup' || pathName === '/welcome'
        ) 
    }

    hideRightMenu = () => {
        const pathName = this.props.location.pathname;
        return (
            pathName === '/on-demand-dry-cleaning&laundry-services' || pathName === '/top-rank-dry-cleaning-and-laundry-services' || pathName === '/college-services'
        ) 
    }

    selectCity = (item) => {
        const cityLink = item.link.replace(/\s+/g, '-');
        this.props.history.push(cityLink)
    }

    showMore = () => {
        if(this.state.showMore){
            this.setState({showMoreTxt: 'More ...'})
        } else {
            this.setState({showMoreTxt: 'Less ...'})
        }
        this.setState({showMore: !this.state.showMore})
    }

    render() {
        const { FooterData } = this.props;
        const { showMore, limit, showMoreTxt } = this.state;
        return (
            <div className="footer-component" style={{display: this.hideFooter() && 'none'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 v-r sub-col">
                            <div className="show-web">
                                <h2>Founders</h2>
                                <div className="">
                                    {FounderList.map((item, index)=> (
                                        <div className="funder-item v-c" key={index}>
                                            <img src={item.photo} alt="people" />
                                            <div className="ml-3">
                                                <div className="name">{item.name}</div>
                                                <div className="role">{item.role}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <h2 className="mt-4">Downloads</h2>
                            <div className="v-r">
                                <a href={ IOS_APP_LINK } className="app-stores" target="_blank" rel="noopener noreferrer"><img src={app_store} alt="app_store" /></a>
                                <a href={ ANDROID_APP_LINK } className="app-stores" target="_blank" rel="noopener noreferrer"><img src={play_store} alt="play_store" className="mt-3"/></a>
                            </div>
                        </div>

                        <div className ="col-lg-9" style={{display: this.hideRightMenu() && 'none'}}>
                            <div className="row">
                                <div className="company-services col-lg-7">
                                    <div className="v-r sub-col">
                                        <h2>Company</h2>
                                        <div className="link-list v-r">
                                            <a className="footer-link" href="/#section-contact-us">Contact Us</a>
                                            <NavLink exact to="/terms" className="footer-link">Privacy</NavLink>
                                            <NavLink exact to="/terms" className="footer-link">Terms</NavLink>
                                            <a className="footer-link" href="/#section-FAQs">FAQs</a>
                                            <NavLink exact to="/blog" className="footer-link">Blog</NavLink>
                                            <NavLink exact to="/service-locations" className="footer-link">Locations</NavLink>
                                        </div>
                                    </div>
                                    <div className="v-r sub-col">
                                        <h2>Services</h2>
                                        <div className="link-list v-r">
                                            <a className="footer-link" href="/#section-services">Business to Business</a>
                                            <a className="footer-link" href="/#section-services">Lockers</a>
                                            <a className="footer-link" href="/#section-services">Storefront</a>
                                            <a className="footer-link" href="/#section-services">Free pick up</a>
                                            <a className="footer-link" href="/#section-services">Airbnb</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="v-r sub-col">
                                        <h2>Locations</h2>
                                        <div className="row city-row">
                                            {FooterData && FooterData.map((item, index)=> (
                                                (!showMore && index < limit) ? <div className="link-list v-r city-item col-lg-6" key={index}>
                                                    {/* <NavLink exact to={item.link} className="footer-link">{item.name}</NavLink> */}
                                                    <div className="footer-link" onClick={()=>this.selectCity(item)}>{item.name}</div>
                                                </div>
                                                : showMore ? <div className="link-list v-r city-item col-lg-6" key={index}>
                                                    <div className="footer-link" onClick={()=>this.selectCity(item)}>{item.name}</div>
                                                </div>
                                                : null
                                            ))}
                                        </div>                                 
                                        <div className="more-btn" onClick={()=>this.showMore()}>{showMoreTxt}</div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="social-links row">
                        <div className="col-lg-6 copy-right order-lg-first order-second">
                            <div className="show-mobile">
                                <h2>Founders</h2>
                                <div className="">
                                    {FounderList.map((item, index)=> (
                                        <div className="funder-item v-c" key={index}>
                                            <img src={item.photo} alt="people" />
                                            <div className="ml-3">
                                                <div className="name">{item.name}</div>
                                                <div className="role">{item.role}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-3 bottom-text">©YellowBag. All rights reserved</div>                            
                        </div>
                        <div className="col-lg-6 soical-icons order-lg-second order-first" style={{display: this.hideRightMenu() && 'none'}}>
                            {SocialLink.map((item, index)=> (
                                <a href={item.link} className="social" target="_blank" rel="noopener noreferrer" key={index}>
                                    <div className="icon-container v-c h-c">
                                        <img src={item.icon} alt="social-icon" />
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        FooterData: state.Footer.FooterData,
        get_footer_data_loading: state.Footer.get_footer_data_loading,
        get_fotter_data_success: state.Footer.get_fotter_data_success,
    }
}

export default connect(mapStateToProps, {getFooterData})(withRouter(Footer));
