import React from 'react';
import './WelcomePage.scss';
import logoActive from '../../Assets/Icons/logo-active.png';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
    WelcomeStart, 
    Steps, 
    WelcomeFooter,
  } from '../../Components';

class WelcomePage extends React.Component {
    
    render() {
        return (
            <div className="welcome-page">
                <div className="logo-area v-c h-c">
                    <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
                </div>
                <WelcomeStart />
                <Steps />
                <WelcomeFooter />
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        
    }
}

export default connect(mapStateToProps, { 
    
})(withRouter(WelcomePage));