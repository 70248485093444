import React from 'react';
import './InputBox.scss';
import 'react-flags-select/scss/react-flags-select.scss';

export default class InputBox extends React.Component {
    state = {
        className: this.props.className || '',
        value: this.props.value || '',
        icon: this.props.icon || '',
        placeholder: this.props.placeholder || '',
        type: this.props.type || 'text',
        focused: false,
        show_forgot: this.props.show_forgot || false,
        phone_input: this.props.phone_input || false,
        country: this.props.country || '',
    }

    onChange = (e) => {
        this.setState({value: e.target.value});
        this.props.onChange(e.target.value);
    }

    onSelectFlag = (val) => {
        this.setState({country: val});
        this.props.onSelect(val);
    }

    render() {
        const { className, value, icon, placeholder, type, focused, show_forgot, phone_input, country } = this.state;
        return (
            <div className={`inputbox-component v-c round-object ${className} ${focused ? 'focused' : ''}`}>
                <div className="input-area v-r">
                    <input className="custom-input" type={type} placeholder={focused ? '' : placeholder} value={value} 
                        onChange={e => this.onChange(e)} 
                        onFocus={e => this.setState({focused: true})}
                        onBlur={e => this.setState({focused: false})}
                    />
                </div>
            </div>
        )
    };
};
