import React from 'react';
import './BlogArticlePage.scss';
import { ArticlePreview, ArticleHeader, Cleaning } from '../../Components';
import facebook from '../../Assets/Common/facebook-logo.png';
import linkedin from '../../Assets/Common/linked-in-logo.png';
import twitter from '../../Assets/Common/twitter-logo.png';
import pinterest from '../../Assets/Common/pinterest-logo.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllPosts } from '../../Redux/Actions';
import { Helmet } from 'react-helmet';
import _ from 'lodash'

function innerFunc(item) {
    return {__html: item}
}

class BlogArticlePage extends React.Component {
    state = {
        article: {},
        startFloat: false,
        post_url: null,
        post: null,
        post_list: [],
    }

    constructor(props) {
        super(props);
        this.otherArticleWrapper = React.createRef();
    }

    componentDidMount() {
        this.setState({ post_url: this.props.match.params.url});
        this.props.getAllPosts();

        window.addEventListener('scroll', this.onWindowScroll);
        // const index = Articles.findIndex(item => item.id === parseInt(this.props.match.params.id, 10));
        // this.setState({
        //     article: Articles[index]
        // })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.post_list !== this.props.post_list && this.props.post_list) {
            const post = this.props.post_list.filter(
                (item) => item.url === this.state.post_url
            );
        
            this.setState({
                article: post && post[0] ? post[0] : null,
                bloglist: this.props.bloglist,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onWindowScroll);
    }
    
    onWindowScroll = (e) => {
        if (window.outerWidth >= 991) {
            this.setState({
                startFloat: window.scrollY >= 600 && window.scrollY < this.otherArticleWrapper.current.offsetTop - 350
            })
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     const index = Articles.findIndex(item => item.id === parseInt(props.match.params.id, 10));
    //     return {
    //         article: Articles[index]
    //     };
    // }

    navigateToArticle = (url) => {
        this.props.history.push(`/blog/${url}`);
    }

    render() {
        const { article, startFloat, post_list } = this.state;
        return (
            <>
                {!_.isEmpty(article) && ( <div className="blogarticle-page">
                    <Helmet>
                        <title>{article.title}</title>
                        <meta name="title" content={article.title} />
                        <meta name="description" content={article.excerpt} />
                        <meta property="type" content="website" />
                        <meta property="url" content={window.location.href}/>
                        <meta property="image" content={article.picture} />

                        {/* <!-- Google / Search Engine Tags --> */}
                        <meta itemprop="name" content={article.title} />
                        <meta itemprop="description" content={article.excerpt} />
                        <meta property="image" content={article.picture} />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={article.title} />
                        <meta property="og:description" content={article.excerpt} />
                        <meta property="image" content={article.picture} />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content={article.title}/>
                        <meta name="twitter:description" content={article.excerpt}/>
                        <meta property="image" content={article.picture} />
                    </Helmet>
                    
                    <ArticleHeader article={article} />
                    <div className="container blogarticle-container">
                        <div className="article-content" dangerouslySetInnerHTML={innerFunc(article.content)} />
                        <div className={`share-area ${startFloat ? 'floating' : ''}`}>
                            <div className="share-title">Share</div>
                            <div className="logo-area">
                                <div className="share-logo"><img src={facebook} alt="share-logo" /></div>
                                <div className="share-logo"><img src={linkedin} alt="share-logo" /></div>
                                <div className="share-logo"><img src={twitter} alt="share-logo" /></div>
                                <div className="share-logo"><img src={pinterest} alt="share-logo" /></div>
                            </div>
                        </div>
                        <div className="article-content">
                            <div className="author-area">
                                <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${article.poster.picture}`} alt='author' className="author-photo" />
                                <div className="author-card shadow-object">
                                    <div className="personal-info">
                                        <div className="author-name">About {article.poster.name}</div>
                                        <div className="author-role">{article.poster.profile_title}</div>
                                    </div>
                                    <div className="author-overview">{article.poster.overview}</div>
                                </div>
                            </div>
                        </div>
                        <Cleaning />
                    </div>
                    <div className="otherarticle-content">
                        <div className="container otherarticle-container" ref={this.otherArticleWrapper}>
                            {!_.isEmpty(post_list) && post_list.length > 2 && <h3>Read Other Articles</h3>}
                            {!_.isEmpty(post_list) && post_list.length > 2 && (
                                <div className="row article-row">
                                    <div className="col-lg-6">
                                        <ArticlePreview data={post_list[1]} onClick={e => this.navigateToArticle(post_list[1].url)}/>
                                    </div>
                                    <div className="col-lg-6">
                                        <ArticlePreview data={post_list[2]} onClick={e => this.navigateToArticle(post_list[2].url)}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>)}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        post_list: state.Post.post_list
    }
}

export default connect(mapStateToProps, { getAllPosts })(withRouter(BlogArticlePage));